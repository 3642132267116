import './App.scss';
import {gsap} from "gsap";
import {ScrollTrigger, MotionPathPlugin} from 'gsap/all';
import React, {useRef, useState, useEffect} from 'react';
import LogoImg from './assets/images/logo.webp';
import FNFLogo from './assets/images/logos/fnf-logo.svg';
import ENLogo from './assets/images/logos/en-logo.png';
import FNFLogoMobile from './assets/images/logos/fnf-logo-round.svg';
import ENLogoMobile from './assets/images/logos/en-logo-round.png';
import Mercury from './assets/images/bg-elements/planets/mercury.webp';
import Venus from './assets/images/bg-elements/planets/venus.webp';
import Earth from './assets/images/bg-elements/planets/earth.webp';
import Mars from './assets/images/bg-elements/planets/mars.webp';
import Jupiter from './assets/images/bg-elements/planets/jupiter.webp';
import Saturn from './assets/images/bg-elements/planets/saturn.webp';
import Uranus from './assets/images/bg-elements/planets/uranus.webp';
import Neptune from './assets/images/bg-elements/planets/neptune.webp';
import Moon from './assets/images/bg-elements/planets/moon.webp';
import Sun from './assets/images/bg-elements/planets/sun.webp';
import Martian from './assets/images/bg-elements/planets/martian.webp';
// import RocketFlames from './assets/images/rocket-with-flames-flip.png';
import {MouseParallaxContainer, MouseParallaxChild} from "react-parallax-mouse";

const App = () => {
  gsap.registerPlugin(MotionPathPlugin);
  const logoRef = useRef();
  const planet = useRef();
  var counter = 0;
  const [see, setSee] = useState(false);
  const [windowDimenion, detectHW] = useState({
    winWidth: window.innerWidth,
    winHeight: window.innerHeight,
  })
  const detectSize = () => {
    detectHW({
      winWidth: window.innerWidth,
      winHeight: window.innerHeight,
    })
  }

  useEffect(() => {
    window.addEventListener('resize', detectSize)

    return () => {
      window.removeEventListener('resize', detectSize)
    }
  }, [windowDimenion]);

  useEffect(() => {
    let logoScale = gsap.context(() => {
      gsap.to(".logo-section", {scaleX: (3 / gsap.getProperty(".logo-section", "scaleX")), 
      scaleY: (3 / gsap.getProperty(".logo-section", "scaleY"))});
    }, logoRef);

    // gsap.to("#rocket", {
    //   duration: 1.5,
    //   ease: "power1.inOut",
    //   motionPath:{
    //     path: "#rocket-path",
    //     align: "#rocket-path",
    //     autoRotate: true,
    //     alignOrigin: [0.5, 0.5]
    //   }
    // }).delay(1.25);

    if(windowDimenion.winWidth <= 640){
      gsap.timeline({defaults: {duration: 1.5}, onUpdate: logoRef})
      .to('.logo-section', {scaleY: .7, scaleX: .7}, 0).delay(1.5);
    } else {
      gsap.timeline({defaults: {duration: 1.5}, onUpdate: logoRef})
      .to('.logo-section', {scaleY: .6, scaleX: .6}, 0).delay(1.5);
    }

    gsap.set(".planet",{scale:"0"});
    gsap.timeline({defaults: {duration: 2}, onUpdate: planet})
    .to('.planet', {scaleY: 1, scaleX: 1}, 0).delay(3);

    gsap.registerPlugin(ScrollTrigger);
    gsap.to(".title-section-card", {
      filter: "blur(8px)",
      ease: "in",
      scrollTrigger: {
        trigger: ".title-section-card",
        start: "top top",
        scrub: true,
      }
    });
    
    return () => logoScale.revert();
  }, []);

  const showMartian = () => {
    counter++;
    if(counter == 5){
      setSee(true);
    }
  };

  const enLink = () =>{ 
    window.open('https://economynext.com/');
  }

  const fnfLink = () =>{ 
    window.open('https://www.freiheit.org/');
  }

  return (
    <>
      <div className='container title-section-container'>
        <div className='title-section-bg'></div>
        <div className='title-section-card'>
          <div className='planet en-logo' onClick={enLink}><img src={ENLogo} /></div>
          <div className='planet fnf-logo' onClick={fnfLink}><img src={FNFLogo} /></div>
          <div className='en-logo-round planet' onClick={enLink}><img src={ENLogoMobile} /></div>
          <div className='fnf-logo-round planet' onClick={fnfLink}><img src={FNFLogoMobile} /></div>
          <div ref={logoRef} className='logo-section'>
            <img src={LogoImg} className='logo' />
          </div>
          {/* <div className='rocket-path-container'>
            <svg width="135%" height="100%" viewBox="0 0 2560 1141">
              <path id="rocket-path" class="st0" fill="none" d="M2093.51,0.85C1638.3,85.97,812.26,346,812.26,768.35c0,143.66,172.92,269.26,341.85,269.26
              c212.83,0,491.87-122.94,491.87-315.54c0-287.97-553.05-444.55-553.05-444.55"/>
            </svg>
          </div> */}
          {/* <img src={RocketFlames} id="rocket" className='background-elements' /> */}
          <MouseParallaxContainer className="parallax" globalFactorX={0.2} globalFactorY={0.2} resetOnLeave>
            <div class="star"></div>
            <div class="star1"></div>
            <div class="star2"></div>
            <div class="star3"></div>
            <section className='planet-section'>
              <div className='planet sun'>
                <MouseParallaxChild factorX={0.075} factorY={0.075} >
                  <img src={Sun} />
                </MouseParallaxChild>
              </div>
              <div className='planet moon'>
                <MouseParallaxChild factorX={0.075} factorY={0.075} >
                  <img src={Moon} />
                </MouseParallaxChild>
              </div>
              <div className='planet mercury'>
                <MouseParallaxChild factorX={0.45} factorY={0.175} >
                  <img src={Mercury} />
                </MouseParallaxChild>
              </div>
              <div className='planet venus'>
                <MouseParallaxChild factorX={0.2} factorY={0.25} >
                  <img src={Venus} />
                </MouseParallaxChild>
              </div>
              <div className='planet earth' ref={planet}>
                <MouseParallaxChild factorX={0.1} factorY={0.1} >
                  <div className='radar'>
                    <img src={Earth} />
                    <div class="speech-bubble">
                      <span className='question'>What is the Rocketship Experience?</span>
                      <span className='answer'>The Rocketship Experience is a digital festival featuring 25 companies and their leaders defying Sri Lanka's market challenges, to build and scale globally competitive businesses. Find out how they reached the stars.</span>
                    </div>
                  </div>
                </MouseParallaxChild>
              </div>
              <div className='planet mars' onClick={() => {showMartian();}}>
                <MouseParallaxChild factorX={0.3} factorY={0.4} >
                  <img src={Mars} />
                  <div className={see ? 'see-martian' : 'martian'}><img src={Martian} /></div>
                </MouseParallaxChild>
              </div>
              <div className='planet jupiter'>
                <MouseParallaxChild factorX={0.1} factorY={0.1} >
                  <div className='radar'>
                    <img src={Jupiter} />
                    <div className='speech-bubble'>
                      <span className='question'>Our Objective</span>
                      <span className='answer'>Our objective is to showcase achievement through eight panel discussions and 11 interviews featuring 25 Sri Lankan private sector leaders who are deploying tech, disrupting the global space and solving big problems</span>
                    </div>
                  </div>
                </MouseParallaxChild>
              </div> 
              <div className='planet saturn'>
                <MouseParallaxChild factorX={0.3} factorY={0.5} >
                  <img src={Saturn} />
                </MouseParallaxChild>
              </div>  
              <div className='planet uranus'>
                <MouseParallaxChild factorX={0.175} factorY={0.4} >
                  <img src={Uranus} />
                </MouseParallaxChild>
              </div>
              <div className='planet neptune'>
                <MouseParallaxChild factorX={0.1} factorY={0.1} >
                  <div className='radar'>
                    <img src={Neptune} />
                    <div className='speech-bubble'>
                      <span className='question'>What can you gain from The Rocketship Experience?</span>
                      <span className='answer'>We showcase what is possible, with a spotlight on innovation, resilience and resourcefulness in the face of challenges, and make the long-term investment case for Sri Lanka</span>
                    </div>
                  </div>
                </MouseParallaxChild>
              </div>
            </section>
          </MouseParallaxContainer>
        </div>  
      </div>
    </>
  );
}

export default App;