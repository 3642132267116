import './App.scss';
import React, {useEffect} from 'react';
import Data from './data.json';
import {Link} from 'react-router-dom';
import {gsap, Sine} from "gsap";
import { ScrollTrigger } from 'gsap/all';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import PanelsTitle from './assets/images/panels-title.png';
import Astronaut from './assets/images/bg-elements/astronaut.png';
import Moon from './assets/images/bg-elements/planets/moon.webp';
import BigRock from './assets/images/bg-elements/big-rock.webp';
import SmallRocks from './assets/images/bg-elements/small-rocks.webp';
import AstronautIcon from './assets/images/panel-astronaut-icon.webp';
import Ground from './assets/images/bg-elements/background/ground.webp';
import Arrow from './assets/images/arrow.svg';
import Rays from './assets/images/bg-elements/background/rays.webp';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faPlay} from '@fortawesome/free-solid-svg-icons';

export default function Panels() {
    const Single = "/video";
    const playIcon = <FontAwesomeIcon icon={faPlay} />;

    useEffect(() => {
        const randomX = random(1, 10);
        const randomY = random(1, 10);
        const randomDelay = random(0, 1);
        const randomTime = random(2, 3);
        const randomTime2 = random(1, 2);
        const randomAngle = random(-10, 10);
        const floatElement = gsap.utils.toArray('.floating');

        floatElement.forEach(float => {
            gsap.set(float, {
                x: randomX(-1),
                y: randomX(1),
                rotation: randomAngle(-1)
            });

            moveX(float, 1);
            moveY(float, -1);
            rotate(float, 1);
        });

        function rotate(target, direction) {
            gsap.to(target, randomTime2(), {
                rotation: randomAngle(direction),
                // delay: randomDelay(),
                ease: Sine.easeInOut,
                onComplete: rotate,
                onCompleteParams: [target, direction * -1]
            });
        }

        function moveX(target, direction) {
            gsap.to(target, randomTime(), {
                x: randomX(direction),
                ease: Sine.easeInOut,
                onComplete: moveX,
                onCompleteParams: [target, direction * -1]
            });
        }

        function moveY(target, direction) {
            gsap.to(target, randomTime(), {
                y: randomY(direction),
                ease: Sine.easeInOut,
                onComplete: moveY,
                onCompleteParams: [target, direction * -1]
            });
        }

        function random(min, max) {
            const delta = max - min;
            return (direction = 1) => (min + delta * Math.random()) * direction;
        }

        gsap.registerPlugin(ScrollTrigger);
        gsap.timeline({
            scrollTrigger: {
                trigger: ".panel-section-spacing",
                scrub: true,
                start: "top top",
            }
        })
        .from(".panel-moon", {
            scale: 0.6,
            ease: "in"
        })

        gsap.timeline({
            scrollTrigger: {
                trigger: ".panel-section-spacing",
                scrub: true,
                start: "top top",
            }
        })
        .from(".panel-astronaut", {
            yPercent: 85,
            ease: "in"
        })
    }, []);

    return (
        <>
            <div className='container' id='panels'>
                <section id='panel-slides'>
                    <div className='panel-section'>
                        <div className='rays-bg'><img src={Rays} /></div>
                        <div className='panel-one'>
                            <div className='rock-stopper-right'>
                                <div className="panels-rocks-right">
                                    <img src={BigRock} className='background-elements big-rock-right floating' />
                                    <img src={SmallRocks} className='background-elements small-rocks-right floating' />
                                </div>
                            </div>
                            <div className='rock-stopper-left'>
                                <div className="panels-rocks-left">
                                    <img src={BigRock} className='background-elements big-rock-left floating' />
                                    <img src={SmallRocks} className='background-elements small-rocks-left floating' />
                                </div>
                            </div>
                            <img src={BigRock} className='background-elements mobile-panel-rock-three floating' />
                            <img src={BigRock} className='background-elements mobile-panel-rock-four floating' />
                            <img src={BigRock} className='background-elements mobile-panel-rock-five floating' />
                            <img src={Astronaut} className='background-elements mobile-panel-astronaut-one floating' />
                            <img src={Astronaut} className='background-elements mobile-panel-astronaut-two floating' />
                            <img src={Ground} className='background-elements ground' />
                            <div className='star'></div>
                            <div className='star1'></div>
                            <div className='star2'></div>
                            <div className='star3'></div>
                            {/* desktop */}
                            <div className='panel-section-spacing panels-desktop'>
                                <div className='row panel-center-content'>
                                    <div className='custom-panel-col'>
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M188.3 147.1C195.8 142.8 205.1 142.1 212.5 147.5L356.5 235.5C363.6 239.9 368 247.6 368 256C368 264.4 363.6 272.1 356.5 276.5L212.5 364.5C205.1 369 195.8 369.2 188.3 364.9C180.7 360.7 176 352.7 176 344V167.1C176 159.3 180.7 151.3 188.3 147.1V147.1zM512 256C512 397.4 397.4 512 256 512C114.6 512 0 397.4 0 256C0 114.6 114.6 0 256 0C397.4 0 512 114.6 512 256zM256 48C141.1 48 48 141.1 48 256C48 370.9 141.1 464 256 464C370.9 464 464 370.9 464 256C464 141.1 370.9 48 256 48z"/></svg>
                                        <Link to={"/video/panels/"+Data.panels[0].url}>
                                            <div className='panel-details'>
                                                <div className="panel-guest-info first-name bounce">
                                                    <img src={AstronautIcon} />
                                                    <div className="panel-guest-name-designation">
                                                        <div className='panel-guest-name'>{Data.panels[0].members.first_person[0].name}</div>
                                                    </div>
                                                    <img src={Arrow} className='panel-arrow arrow-one' />
                                                </div>
                                                <div className="panel-guest-info second-name bounce">
                                                    <img src={AstronautIcon} />
                                                    <div className="panel-guest-name-designation">
                                                        <div className='panel-guest-name'>{Data.panels[0].members.second_person[0].name}</div>
                                                    </div>
                                                    <img src={Arrow} className='panel-arrow arrow-two' />
                                                </div>
                                                <div className="panel-guest-info third-name bounce">
                                                    <img src={AstronautIcon} />
                                                    <div className="panel-guest-name-designation">
                                                        <div className='panel-guest-name'>{Data.panels[0].members.third_person[0].name}</div>
                                                    </div>
                                                    <img src={Arrow} className='panel-arrow arrow-three' />
                                                </div>
                                            </div>
                                            <div className='panel-guests-img'><img src={Data.panels[0].thumbnail} /></div>
                                        </Link>
                                    </div>
                                    <div className='col-2 panel-center-graphics'>
                                        <div className='panel-section-title'><img src={PanelsTitle} /></div>
                                        <div className='panel-moon-and-astronaut'>
                                            <img className='panel-astronaut floating' src={Astronaut} />
                                            <img className='panel-moon' src={Moon} />
                                        </div>
                                    </div>
                                    <div className='custom-panel-col'>
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M188.3 147.1C195.8 142.8 205.1 142.1 212.5 147.5L356.5 235.5C363.6 239.9 368 247.6 368 256C368 264.4 363.6 272.1 356.5 276.5L212.5 364.5C205.1 369 195.8 369.2 188.3 364.9C180.7 360.7 176 352.7 176 344V167.1C176 159.3 180.7 151.3 188.3 147.1V147.1zM512 256C512 397.4 397.4 512 256 512C114.6 512 0 397.4 0 256C0 114.6 114.6 0 256 0C397.4 0 512 114.6 512 256zM256 48C141.1 48 48 141.1 48 256C48 370.9 141.1 464 256 464C370.9 464 464 370.9 464 256C464 141.1 370.9 48 256 48z"/></svg>
                                        <Link to={"/video/panels/"+Data.panels[1].url}>
                                            <div className='panel-details'>
                                                <div className="panel-guest-info first-name bounce">
                                                    <img src={AstronautIcon} />
                                                    <div className="panel-guest-name-designation">
                                                        <div className='panel-guest-name'>{Data.panels[1].members.first_person[0].name}</div>
                                                    </div>
                                                    <img src={Arrow} className='panel-arrow arrow-one' />
                                                </div>
                                                <div className="panel-guest-info second-name bounce">
                                                    <img src={AstronautIcon} />
                                                    <div className="panel-guest-name-designation">
                                                        <div className='panel-guest-name'>{Data.panels[1].members.second_person[0].name}</div>
                                                    </div>
                                                    <img src={Arrow} className='panel-arrow arrow-two' />
                                                </div>
                                                <div className="panel-guest-info third-name bounce">
                                                    <img src={AstronautIcon} />
                                                    <div className="panel-guest-name-designation">
                                                        <div className='panel-guest-name'>{Data.panels[1].members.third_person[0].name}</div>
                                                    </div>
                                                    <img src={Arrow} className='panel-arrow arrow-three' />
                                                </div>
                                            </div>
                                            <div className='panel-guests-img'><img src={Data.panels[1].thumbnail} /></div>
                                        </Link>
                                    </div>
                                    <div className='custom-panel-col'>
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M188.3 147.1C195.8 142.8 205.1 142.1 212.5 147.5L356.5 235.5C363.6 239.9 368 247.6 368 256C368 264.4 363.6 272.1 356.5 276.5L212.5 364.5C205.1 369 195.8 369.2 188.3 364.9C180.7 360.7 176 352.7 176 344V167.1C176 159.3 180.7 151.3 188.3 147.1V147.1zM512 256C512 397.4 397.4 512 256 512C114.6 512 0 397.4 0 256C0 114.6 114.6 0 256 0C397.4 0 512 114.6 512 256zM256 48C141.1 48 48 141.1 48 256C48 370.9 141.1 464 256 464C370.9 464 464 370.9 464 256C464 141.1 370.9 48 256 48z"/></svg>
                                        <Link to={"/video/panels/"+Data.panels[2].url}>
                                            <div className='panel-details'>
                                                <div className="panel-guest-info first-name bounce">
                                                    <img src={AstronautIcon} />
                                                    <div className="panel-guest-name-designation">
                                                        <div className='panel-guest-name'>{Data.panels[2].members.first_person[0].name}</div>
                                                    </div>
                                                    <img src={Arrow} className='panel-arrow arrow-one' />
                                                </div>
                                                <div className="panel-guest-info second-name bounce">
                                                    <img src={AstronautIcon} />
                                                    <div className="panel-guest-name-designation">
                                                        <div className='panel-guest-name'>{Data.panels[2].members.second_person[0].name}</div>
                                                    </div>
                                                    <img src={Arrow} className='panel-arrow arrow-two' />
                                                </div>
                                                <div className="panel-guest-info third-name bounce">
                                                    <img src={AstronautIcon} />
                                                    <div className="panel-guest-name-designation">
                                                        <div className='panel-guest-name'>{Data.panels[2].members.third_person[0].name}</div>
                                                    </div>
                                                    <img src={Arrow} className='panel-arrow arrow-three' />
                                                </div>
                                            </div>
                                            <div className='panel-guests-img'><img src={Data.panels[2].thumbnail} /></div>
                                        </Link>
                                    </div>
                                    <div className='col-2 panel-center-graphics'></div>
                                    <div className='custom-panel-col'>
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M188.3 147.1C195.8 142.8 205.1 142.1 212.5 147.5L356.5 235.5C363.6 239.9 368 247.6 368 256C368 264.4 363.6 272.1 356.5 276.5L212.5 364.5C205.1 369 195.8 369.2 188.3 364.9C180.7 360.7 176 352.7 176 344V167.1C176 159.3 180.7 151.3 188.3 147.1V147.1zM512 256C512 397.4 397.4 512 256 512C114.6 512 0 397.4 0 256C0 114.6 114.6 0 256 0C397.4 0 512 114.6 512 256zM256 48C141.1 48 48 141.1 48 256C48 370.9 141.1 464 256 464C370.9 464 464 370.9 464 256C464 141.1 370.9 48 256 48z"/></svg>
                                        <Link to={"/video/panels/"+Data.panels[3].url}>
                                            <div className='panel-details'>
                                                <div className="panel-guest-info first-name bounce">
                                                    <img src={AstronautIcon} />
                                                    <div className="panel-guest-name-designation">
                                                        <div className='panel-guest-name'>{Data.panels[3].members.first_person[0].name}</div>
                                                    </div>
                                                    <img src={Arrow} className='panel-arrow arrow-one' />
                                                </div>
                                                <div className="panel-guest-info second-name bounce">
                                                    <img src={AstronautIcon} />
                                                    <div className="panel-guest-name-designation">
                                                        <div className='panel-guest-name'>{Data.panels[3].members.second_person[0].name}</div>
                                                    </div>
                                                    <img src={Arrow} className='panel-arrow arrow-two' />
                                                </div>
                                                <div className="panel-guest-info third-name bounce">
                                                    <img src={AstronautIcon} />
                                                    <div className="panel-guest-name-designation">
                                                        <div className='panel-guest-name'>{Data.panels[3].members.third_person[0].name}</div>
                                                    </div>
                                                    <img src={Arrow} className='panel-arrow arrow-three' />
                                                </div>
                                            </div>
                                            <div className='panel-guests-img'><img src={Data.panels[3].thumbnail} /></div>
                                        </Link>
                                    </div>
                                    <div className='custom-panel-col'>
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M188.3 147.1C195.8 142.8 205.1 142.1 212.5 147.5L356.5 235.5C363.6 239.9 368 247.6 368 256C368 264.4 363.6 272.1 356.5 276.5L212.5 364.5C205.1 369 195.8 369.2 188.3 364.9C180.7 360.7 176 352.7 176 344V167.1C176 159.3 180.7 151.3 188.3 147.1V147.1zM512 256C512 397.4 397.4 512 256 512C114.6 512 0 397.4 0 256C0 114.6 114.6 0 256 0C397.4 0 512 114.6 512 256zM256 48C141.1 48 48 141.1 48 256C48 370.9 141.1 464 256 464C370.9 464 464 370.9 464 256C464 141.1 370.9 48 256 48z"/></svg>
                                        <Link to={"/video/panels/"+Data.panels[4].url}>
                                            <div className='panel-details'>
                                                <div className="panel-guest-info first-name bounce">
                                                    <img src={AstronautIcon} />
                                                    <div className="panel-guest-name-designation">
                                                        <div className='panel-guest-name'>{Data.panels[4].members.first_person[0].name}</div>
                                                    </div>
                                                    <img src={Arrow} className='panel-arrow arrow-one' />
                                                </div>
                                                <div className="panel-guest-info second-name bounce">
                                                    <img src={AstronautIcon} />
                                                    <div className="panel-guest-name-designation">
                                                        <div className='panel-guest-name'>{Data.panels[4].members.second_person[0].name}</div>
                                                    </div>
                                                    <img src={Arrow} className='panel-arrow arrow-two' />
                                                </div>
                                                <div className="panel-guest-info third-name bounce">
                                                    <img src={AstronautIcon} />
                                                    <div className="panel-guest-name-designation">
                                                        <div className='panel-guest-name'>{Data.panels[4].members.third_person[0].name}</div>
                                                    </div>
                                                    <img src={Arrow} className='panel-arrow arrow-three' />
                                                </div>
                                            </div>
                                            <div className='panel-guests-img'><img src={Data.panels[4].thumbnail} /></div>
                                        </Link>
                                    </div>
                                    <div className='col-2 panel-center-graphics'></div>
                                    <div className='custom-panel-col'>
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M188.3 147.1C195.8 142.8 205.1 142.1 212.5 147.5L356.5 235.5C363.6 239.9 368 247.6 368 256C368 264.4 363.6 272.1 356.5 276.5L212.5 364.5C205.1 369 195.8 369.2 188.3 364.9C180.7 360.7 176 352.7 176 344V167.1C176 159.3 180.7 151.3 188.3 147.1V147.1zM512 256C512 397.4 397.4 512 256 512C114.6 512 0 397.4 0 256C0 114.6 114.6 0 256 0C397.4 0 512 114.6 512 256zM256 48C141.1 48 48 141.1 48 256C48 370.9 141.1 464 256 464C370.9 464 464 370.9 464 256C464 141.1 370.9 48 256 48z"/></svg>
                                        <Link to={"/video/panels/"+Data.panels[5].url}>
                                            <div className='panel-details'>
                                                <div className="panel-guest-info first-name bounce">
                                                    <img src={AstronautIcon} />
                                                    <div className="panel-guest-name-designation">
                                                        <div className='panel-guest-name'>{Data.panels[5].members.first_person[0].name}</div>
                                                    </div>
                                                    <img src={Arrow} className='panel-arrow arrow-one' />
                                                </div>
                                                <div className="panel-guest-info second-name bounce">
                                                    <img src={AstronautIcon} />
                                                    <div className="panel-guest-name-designation">
                                                        <div className='panel-guest-name'>{Data.panels[5].members.second_person[0].name}</div>
                                                    </div>
                                                    <img src={Arrow} className='panel-arrow arrow-two' />
                                                </div>
                                                <div className="panel-guest-info third-name bounce">
                                                    <img src={AstronautIcon} />
                                                    <div className="panel-guest-name-designation">
                                                        <div className='panel-guest-name'>{Data.panels[5].members.third_person[0].name}</div>
                                                    </div>
                                                    <img src={Arrow} className='panel-arrow arrow-three' />
                                                </div>
                                            </div>
                                            <div className='panel-guests-img'><img src={Data.panels[5].thumbnail} /></div>
                                        </Link>
                                    </div>
                                    <div className='custom-panel-col'>
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M188.3 147.1C195.8 142.8 205.1 142.1 212.5 147.5L356.5 235.5C363.6 239.9 368 247.6 368 256C368 264.4 363.6 272.1 356.5 276.5L212.5 364.5C205.1 369 195.8 369.2 188.3 364.9C180.7 360.7 176 352.7 176 344V167.1C176 159.3 180.7 151.3 188.3 147.1V147.1zM512 256C512 397.4 397.4 512 256 512C114.6 512 0 397.4 0 256C0 114.6 114.6 0 256 0C397.4 0 512 114.6 512 256zM256 48C141.1 48 48 141.1 48 256C48 370.9 141.1 464 256 464C370.9 464 464 370.9 464 256C464 141.1 370.9 48 256 48z"/></svg>
                                        <Link to={"/video/panels/"+Data.panels[6].url}>
                                            <div className='panel-details'>
                                                <div className="panel-guest-info first-name bounce">
                                                    <img src={AstronautIcon} />
                                                    <div className="panel-guest-name-designation">
                                                        <div className='panel-guest-name'>{Data.panels[6].members.first_person[0].name}</div>
                                                    </div>
                                                    <img src={Arrow} className='panel-arrow arrow-one' />
                                                </div>
                                                <div className="panel-guest-info second-name bounce">
                                                    <img src={AstronautIcon} />
                                                    <div className="panel-guest-name-designation">
                                                        <div className='panel-guest-name'>{Data.panels[6].members.second_person[0].name}</div>
                                                    </div>
                                                    <img src={Arrow} className='panel-arrow arrow-two' />
                                                </div>
                                                <div className="panel-guest-info third-name bounce">
                                                    <img src={AstronautIcon} />
                                                    <div className="panel-guest-name-designation">
                                                        <div className='panel-guest-name'>{Data.panels[6].members.third_person[0].name}</div>
                                                    </div>
                                                    <img src={Arrow} className='panel-arrow arrow-three' />
                                                </div>
                                            </div>
                                            <div className='panel-guests-img'><img src={Data.panels[6].thumbnail} /></div>
                                        </Link>
                                    </div>
                                    <div className='col-2 panel-center-graphics'></div>
                                    <div className='custom-panel-col'>
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M188.3 147.1C195.8 142.8 205.1 142.1 212.5 147.5L356.5 235.5C363.6 239.9 368 247.6 368 256C368 264.4 363.6 272.1 356.5 276.5L212.5 364.5C205.1 369 195.8 369.2 188.3 364.9C180.7 360.7 176 352.7 176 344V167.1C176 159.3 180.7 151.3 188.3 147.1V147.1zM512 256C512 397.4 397.4 512 256 512C114.6 512 0 397.4 0 256C0 114.6 114.6 0 256 0C397.4 0 512 114.6 512 256zM256 48C141.1 48 48 141.1 48 256C48 370.9 141.1 464 256 464C370.9 464 464 370.9 464 256C464 141.1 370.9 48 256 48z"/></svg>
                                        <Link to={"/video/panels/"+Data.panels[7].url}>
                                            <div className='panel-details'>
                                                <div className="panel-guest-info first-name bounce">
                                                    <img src={AstronautIcon} />
                                                    <div className="panel-guest-name-designation">
                                                        <div className='panel-guest-name'>{Data.panels[7].members.first_person[0].name}</div>
                                                    </div>
                                                    <img src={Arrow} className='panel-arrow arrow-one' />
                                                </div>
                                                <div className="panel-guest-info second-name bounce">
                                                    <img src={AstronautIcon} />
                                                    <div className="panel-guest-name-designation">
                                                        <div className='panel-guest-name'>{Data.panels[7].members.second_person[0].name}</div>
                                                    </div>
                                                    <img src={Arrow} className='panel-arrow arrow-two' />
                                                </div>
                                                <div className="panel-guest-info third-name bounce">
                                                    <img src={AstronautIcon} />
                                                    <div className="panel-guest-name-designation">
                                                        <div className='panel-guest-name'>{Data.panels[7].members.third_person[0].name}</div>
                                                    </div>
                                                    <img src={Arrow} className='panel-arrow arrow-three' />
                                                </div>
                                            </div>
                                            <div className='panel-guests-img'><img src={Data.panels[7].thumbnail} /></div>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                            {/* mobile */}
                            <div className='panel-section-spacing panels-mobile'>
                                <div className='row panel-center-content'>
                                    <div className='panel-section-title'><img src={PanelsTitle} /></div>
                                    <Link to={"/video/panels/"+Data.panels[0].url}>
                                        <div className='custom-panel-col'>
                                            <div className='panel-details bounce'>
                                                <div className="panel-guest-info first-name">
                                                    <img src={AstronautIcon} />
                                                    <div className="panel-guest-name-designation">
                                                        <div className='panel-guest-name'>{Data.panels[0].members.first_person[0].name}</div>
                                                    </div>
                                                    <img src={Arrow} className='panel-arrow arrow-one' />
                                                </div>
                                                <div className="panel-guest-info second-name">
                                                    <img src={AstronautIcon} />
                                                    <div className="panel-guest-name-designation">
                                                        <div className='panel-guest-name'>{Data.panels[0].members.second_person[0].name}</div>
                                                    </div>
                                                    <img src={Arrow} className='panel-arrow arrow-two' />
                                                </div>
                                                <div className="panel-guest-info third-name">
                                                    <img src={AstronautIcon} />
                                                    <div className="panel-guest-name-designation">
                                                        <div className='panel-guest-name'>{Data.panels[0].members.third_person[0].name}</div>
                                                    </div>
                                                    <img src={Arrow} className='panel-arrow arrow-three' />
                                                </div>
                                            </div>
                                            <div className='panel-guests-img'><img src={Data.panels[0].thumbnail} /></div>
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M188.3 147.1C195.8 142.8 205.1 142.1 212.5 147.5L356.5 235.5C363.6 239.9 368 247.6 368 256C368 264.4 363.6 272.1 356.5 276.5L212.5 364.5C205.1 369 195.8 369.2 188.3 364.9C180.7 360.7 176 352.7 176 344V167.1C176 159.3 180.7 151.3 188.3 147.1V147.1zM512 256C512 397.4 397.4 512 256 512C114.6 512 0 397.4 0 256C0 114.6 114.6 0 256 0C397.4 0 512 114.6 512 256zM256 48C141.1 48 48 141.1 48 256C48 370.9 141.1 464 256 464C370.9 464 464 370.9 464 256C464 141.1 370.9 48 256 48z"/></svg>
                                        </div>
                                    </Link>
                                    <Link to={"/video/panels/"+Data.panels[1].url}>
                                        <div className='custom-panel-col'>
                                            <div className='panel-details bounce'>
                                                <div className="panel-guest-info first-name">
                                                    <img src={AstronautIcon} />
                                                    <div className="panel-guest-name-designation">
                                                        <div className='panel-guest-name'>{Data.panels[1].members.first_person[0].name}</div>
                                                    </div>
                                                    <img src={Arrow} className='panel-arrow arrow-one' />
                                                </div>
                                                <div className="panel-guest-info second-name">
                                                    <img src={AstronautIcon} />
                                                    <div className="panel-guest-name-designation">
                                                        <div className='panel-guest-name'>{Data.panels[1].members.second_person[0].name}</div>
                                                    </div>
                                                    <img src={Arrow} className='panel-arrow arrow-two' />
                                                </div>
                                                <div className="panel-guest-info third-name">
                                                    <img src={AstronautIcon} />
                                                    <div className="panel-guest-name-designation">
                                                        <div className='panel-guest-name'>{Data.panels[1].members.third_person[0].name}</div>
                                                    </div>
                                                    <img src={Arrow} className='panel-arrow arrow-three' />
                                                </div>
                                            </div>
                                            <div className='panel-guests-img'><img src={Data.panels[1].thumbnail} /></div>
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M188.3 147.1C195.8 142.8 205.1 142.1 212.5 147.5L356.5 235.5C363.6 239.9 368 247.6 368 256C368 264.4 363.6 272.1 356.5 276.5L212.5 364.5C205.1 369 195.8 369.2 188.3 364.9C180.7 360.7 176 352.7 176 344V167.1C176 159.3 180.7 151.3 188.3 147.1V147.1zM512 256C512 397.4 397.4 512 256 512C114.6 512 0 397.4 0 256C0 114.6 114.6 0 256 0C397.4 0 512 114.6 512 256zM256 48C141.1 48 48 141.1 48 256C48 370.9 141.1 464 256 464C370.9 464 464 370.9 464 256C464 141.1 370.9 48 256 48z"/></svg>
                                        </div>
                                    </Link>
                                    <Link to={"/video/panels/"+Data.panels[2].url}>
                                        <div className='custom-panel-col'>
                                            <div className='panel-details bounce'>
                                                <div className="panel-guest-info first-name">
                                                    <img src={AstronautIcon} />
                                                    <div className="panel-guest-name-designation">
                                                        <div className='panel-guest-name'>{Data.panels[2].members.first_person[0].name}</div>
                                                    </div>
                                                    <img src={Arrow} className='panel-arrow arrow-one' />
                                                </div>
                                                <div className="panel-guest-info second-name">
                                                    <img src={AstronautIcon} />
                                                    <div className="panel-guest-name-designation">
                                                        <div className='panel-guest-name'>{Data.panels[2].members.second_person[0].name}</div>
                                                    </div>
                                                    <img src={Arrow} className='panel-arrow arrow-two' />
                                                </div>
                                                <div className="panel-guest-info third-name">
                                                    <img src={AstronautIcon} />
                                                    <div className="panel-guest-name-designation">
                                                        <div className='panel-guest-name'>{Data.panels[2].members.third_person[0].name}</div>
                                                    </div>
                                                    <img src={Arrow} className='panel-arrow arrow-three' />
                                                </div>
                                            </div>
                                            <div className='panel-guests-img'><img src={Data.panels[2].thumbnail} /></div>
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M188.3 147.1C195.8 142.8 205.1 142.1 212.5 147.5L356.5 235.5C363.6 239.9 368 247.6 368 256C368 264.4 363.6 272.1 356.5 276.5L212.5 364.5C205.1 369 195.8 369.2 188.3 364.9C180.7 360.7 176 352.7 176 344V167.1C176 159.3 180.7 151.3 188.3 147.1V147.1zM512 256C512 397.4 397.4 512 256 512C114.6 512 0 397.4 0 256C0 114.6 114.6 0 256 0C397.4 0 512 114.6 512 256zM256 48C141.1 48 48 141.1 48 256C48 370.9 141.1 464 256 464C370.9 464 464 370.9 464 256C464 141.1 370.9 48 256 48z"/></svg>
                                        </div>
                                    </Link>
                                    <Link to={"/video/panels/"+Data.panels[3].url}>
                                        <div className='custom-panel-col'>
                                            <div className='panel-details bounce'>
                                                <div className="panel-guest-info first-name">
                                                    <img src={AstronautIcon} />
                                                    <div className="panel-guest-name-designation">
                                                        <div className='panel-guest-name'>{Data.panels[3].members.first_person[0].name}</div>
                                                    </div>
                                                    <img src={Arrow} className='panel-arrow arrow-one' />
                                                </div>
                                                <div className="panel-guest-info second-name">
                                                    <img src={AstronautIcon} />
                                                    <div className="panel-guest-name-designation">
                                                        <div className='panel-guest-name'>{Data.panels[3].members.second_person[0].name}</div>
                                                    </div>
                                                    <img src={Arrow} className='panel-arrow arrow-two' />
                                                </div>
                                                <div className="panel-guest-info third-name">
                                                    <img src={AstronautIcon} />
                                                    <div className="panel-guest-name-designation">
                                                        <div className='panel-guest-name'>{Data.panels[3].members.third_person[0].name}</div>
                                                    </div>
                                                    <img src={Arrow} className='panel-arrow arrow-three' />
                                                </div>
                                            </div>
                                            <div className='panel-guests-img'><img src={Data.panels[3].thumbnail} /></div>
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M188.3 147.1C195.8 142.8 205.1 142.1 212.5 147.5L356.5 235.5C363.6 239.9 368 247.6 368 256C368 264.4 363.6 272.1 356.5 276.5L212.5 364.5C205.1 369 195.8 369.2 188.3 364.9C180.7 360.7 176 352.7 176 344V167.1C176 159.3 180.7 151.3 188.3 147.1V147.1zM512 256C512 397.4 397.4 512 256 512C114.6 512 0 397.4 0 256C0 114.6 114.6 0 256 0C397.4 0 512 114.6 512 256zM256 48C141.1 48 48 141.1 48 256C48 370.9 141.1 464 256 464C370.9 464 464 370.9 464 256C464 141.1 370.9 48 256 48z"/></svg>
                                        </div>
                                    </Link>
                                    <Link to={"/video/panels/"+Data.panels[4].url}>
                                        <div className='custom-panel-col'>
                                            <div className='panel-details bounce'>
                                                <div className="panel-guest-info first-name">
                                                    <img src={AstronautIcon} />
                                                    <div className="panel-guest-name-designation">
                                                        <div className='panel-guest-name'>{Data.panels[4].members.first_person[0].name}</div>
                                                    </div>
                                                    <img src={Arrow} className='panel-arrow arrow-one' />
                                                </div>
                                                <div className="panel-guest-info second-name">
                                                    <img src={AstronautIcon} />
                                                    <div className="panel-guest-name-designation maud-mobile">
                                                        <div className='panel-guest-name'>{Data.panels[4].members.second_person[0].name}</div>
                                                    </div>
                                                    <img src={Arrow} className='panel-arrow arrow-two' />
                                                </div>
                                                <div className="panel-guest-info third-name">
                                                    <img src={AstronautIcon} />
                                                    <div className="panel-guest-name-designation">
                                                        <div className='panel-guest-name'>{Data.panels[4].members.third_person[0].name}</div>
                                                    </div>
                                                    <img src={Arrow} className='panel-arrow arrow-three' />
                                                </div>
                                            </div>
                                            <div className='panel-guests-img'><img src={Data.panels[4].thumbnail} /></div>
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M188.3 147.1C195.8 142.8 205.1 142.1 212.5 147.5L356.5 235.5C363.6 239.9 368 247.6 368 256C368 264.4 363.6 272.1 356.5 276.5L212.5 364.5C205.1 369 195.8 369.2 188.3 364.9C180.7 360.7 176 352.7 176 344V167.1C176 159.3 180.7 151.3 188.3 147.1V147.1zM512 256C512 397.4 397.4 512 256 512C114.6 512 0 397.4 0 256C0 114.6 114.6 0 256 0C397.4 0 512 114.6 512 256zM256 48C141.1 48 48 141.1 48 256C48 370.9 141.1 464 256 464C370.9 464 464 370.9 464 256C464 141.1 370.9 48 256 48z"/></svg>
                                        </div>
                                    </Link>
                                    <Link to={"/video/panels/"+Data.panels[5].url}>
                                        <div className='custom-panel-col'>
                                            <div className='panel-details bounce'>
                                                <div className="panel-guest-info first-name">
                                                    <img src={AstronautIcon} />
                                                    <div className="panel-guest-name-designation gayani-mobile">
                                                        <div className='panel-guest-name'>{Data.panels[5].members.first_person[0].name}</div>
                                                    </div>
                                                    <img src={Arrow} className='panel-arrow arrow-one' />
                                                </div>
                                                <div className="panel-guest-info second-name">
                                                    <img src={AstronautIcon} />
                                                    <div className="panel-guest-name-designation">
                                                        <div className='panel-guest-name'>{Data.panels[5].members.second_person[0].name}</div>
                                                    </div>
                                                    <img src={Arrow} className='panel-arrow arrow-two' />
                                                </div>
                                                <div className="panel-guest-info third-name">
                                                    <img src={AstronautIcon} />
                                                    <div className="panel-guest-name-designation">
                                                        <div className='panel-guest-name'>{Data.panels[5].members.third_person[0].name}</div>
                                                    </div>
                                                    <img src={Arrow} className='panel-arrow arrow-three' />
                                                </div>
                                            </div>
                                            <div className='panel-guests-img'><img src={Data.panels[5].thumbnail} /></div>
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M188.3 147.1C195.8 142.8 205.1 142.1 212.5 147.5L356.5 235.5C363.6 239.9 368 247.6 368 256C368 264.4 363.6 272.1 356.5 276.5L212.5 364.5C205.1 369 195.8 369.2 188.3 364.9C180.7 360.7 176 352.7 176 344V167.1C176 159.3 180.7 151.3 188.3 147.1V147.1zM512 256C512 397.4 397.4 512 256 512C114.6 512 0 397.4 0 256C0 114.6 114.6 0 256 0C397.4 0 512 114.6 512 256zM256 48C141.1 48 48 141.1 48 256C48 370.9 141.1 464 256 464C370.9 464 464 370.9 464 256C464 141.1 370.9 48 256 48z"/></svg>
                                        </div>
                                    </Link>
                                    <Link to={"/video/panels/"+Data.panels[6].url}>
                                        <div className='custom-panel-col'>
                                            <div className='panel-details bounce'>
                                                <div className="panel-guest-info first-name">
                                                    <img src={AstronautIcon} />
                                                    <div className="panel-guest-name-designation">
                                                        <div className='panel-guest-name'>{Data.panels[6].members.first_person[0].name}</div>
                                                    </div>
                                                    <img src={Arrow} className='panel-arrow arrow-one' />
                                                </div>
                                                <div className="panel-guest-info second-name">
                                                    <img src={AstronautIcon} />
                                                    <div className="panel-guest-name-designation">
                                                        <div className='panel-guest-name'>{Data.panels[6].members.second_person[0].name}</div>
                                                    </div>
                                                    <img src={Arrow} className='panel-arrow arrow-two' />
                                                </div>
                                                <div className="panel-guest-info third-name">
                                                    <img src={AstronautIcon} />
                                                    <div className="panel-guest-name-designation">
                                                        <div className='panel-guest-name'>{Data.panels[6].members.third_person[0].name}</div>
                                                    </div>
                                                    <img src={Arrow} className='panel-arrow arrow-three' />
                                                </div>
                                            </div>
                                            <div className='panel-guests-img'><img src={Data.panels[6].thumbnail} /></div>
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M188.3 147.1C195.8 142.8 205.1 142.1 212.5 147.5L356.5 235.5C363.6 239.9 368 247.6 368 256C368 264.4 363.6 272.1 356.5 276.5L212.5 364.5C205.1 369 195.8 369.2 188.3 364.9C180.7 360.7 176 352.7 176 344V167.1C176 159.3 180.7 151.3 188.3 147.1V147.1zM512 256C512 397.4 397.4 512 256 512C114.6 512 0 397.4 0 256C0 114.6 114.6 0 256 0C397.4 0 512 114.6 512 256zM256 48C141.1 48 48 141.1 48 256C48 370.9 141.1 464 256 464C370.9 464 464 370.9 464 256C464 141.1 370.9 48 256 48z"/></svg>
                                        </div>
                                    </Link>
                                    <Link to={"/video/panels/"+Data.panels[7].url}>
                                        <div className='custom-panel-col'>
                                            <div className='panel-details bounce'>
                                                <div className="panel-guest-info first-name">
                                                    <img src={AstronautIcon} />
                                                    <div className="panel-guest-name-designation">
                                                        <div className='panel-guest-name'>{Data.panels[7].members.first_person[0].name}</div>
                                                    </div>
                                                    <img src={Arrow} className='panel-arrow arrow-one' />
                                                </div>
                                                <div className="panel-guest-info second-name">
                                                    <img src={AstronautIcon} />
                                                    <div className="panel-guest-name-designation">
                                                        <div className='panel-guest-name'>{Data.panels[7].members.second_person[0].name}</div>
                                                    </div>
                                                    <img src={Arrow} className='panel-arrow arrow-two' />
                                                </div>
                                                <div className="panel-guest-info third-name">
                                                    <img src={AstronautIcon} />
                                                    <div className="panel-guest-name-designation">
                                                        <div className='panel-guest-name'>{Data.panels[7].members.third_person[0].name}</div>
                                                    </div>
                                                    <img src={Arrow} className='panel-arrow arrow-three' />
                                                </div>
                                            </div>
                                            <div className='panel-guests-img'><img src={Data.panels[7].thumbnail} /></div>
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M188.3 147.1C195.8 142.8 205.1 142.1 212.5 147.5L356.5 235.5C363.6 239.9 368 247.6 368 256C368 264.4 363.6 272.1 356.5 276.5L212.5 364.5C205.1 369 195.8 369.2 188.3 364.9C180.7 360.7 176 352.7 176 344V167.1C176 159.3 180.7 151.3 188.3 147.1V147.1zM512 256C512 397.4 397.4 512 256 512C114.6 512 0 397.4 0 256C0 114.6 114.6 0 256 0C397.4 0 512 114.6 512 256zM256 48C141.1 48 48 141.1 48 256C48 370.9 141.1 464 256 464C370.9 464 464 370.9 464 256C464 141.1 370.9 48 256 48z"/></svg>
                                        </div>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </>
    );
}