import React from 'react';
import ReactDOM from 'react-dom/client';
import {BrowserRouter, Routes, Route} from "react-router-dom";
import App from './App';
import Panels from './Panels.jsx';
import Conversations from './Conversations.jsx';
import Footer from './Footer.jsx';
import ScrollToTop from './ScrollToTop.jsx';
import SinglePanels from './SinglePanels.jsx';
import SingleConversations from './SingleConversations.jsx';
import SingleRapidFire from './SingleRapidFire.jsx';
import GoToTop from './GoToTop';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<><App /><Panels /><Conversations /><Footer /><ScrollToTop /><GoToTop /></>}></Route>
        <Route path="video/panels/:id" element={<><SinglePanels /><ScrollToTop /><Footer /><GoToTop /></>}></Route>
        <Route path="video/conversations/:id" element={<><SingleConversations /><ScrollToTop /><Footer /><GoToTop /></>}></Route>
        <Route path="video/rapid-fire/:id" element={<><SingleRapidFire /><ScrollToTop /><Footer /><GoToTop /></>}></Route>
      </Routes>
    </BrowserRouter>
  </>
);