import './App.scss';
import React, {useState, useEffect} from 'react';
import Data from './data.json';
import {gsap, Sine} from "gsap";
import LogoImg from './assets/images/logo.webp';
import FNFLogo from './assets/images/logos/fnf-logo.svg';
import ENLogo from './assets/images/logos/en-logo.png';
import FNFLogoMobile from './assets/images/logos/fnf-logo-round.svg';
import ENLogoMobile from './assets/images/logos/en-logo-round.png';
import CloudSmoke from './assets/images/bg-elements/background/cloud-smoke.webp';
import SmokeOne from './assets/images/bg-elements/background/smoke-one.webp';
import SmokeTwo from './assets/images/bg-elements/background/smoke-two.webp';
import Earth from './assets/images/bg-elements/planets/earth.webp';
import Mars from './assets/images/bg-elements/planets/mars.webp';
import Saturn from './assets/images/bg-elements/planets/saturn.webp';
import Uranus from './assets/images/bg-elements/planets/uranus.webp';
import Neptune from './assets/images/bg-elements/planets/neptune.webp';
import Moon from './assets/images/bg-elements/planets/moon.webp';
import RocketFlames from './assets/images/rocket-with-flames.svg';
import RocketSmoke from './assets/images/bg-elements/rocket-smoke-blue.webp';
import {Link} from 'react-router-dom';
import {useParams} from 'react-router-dom';
import RapidFire from './assets/images/rapid-fire-icon.webp';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faPlay} from '@fortawesome/free-solid-svg-icons';

export default function SingleConversations() {
  const BackLink = "/";
  const {id} = useParams();
  const playIcon = <FontAwesomeIcon icon={faPlay} />;
  let isDefined = false;

  function getRandomThumb(arr, num) {
    const shuffled = [...arr].sort(() => 0.5 - Math.random());
    return shuffled.slice(0, num);
  }

  function getSixThumbnails(){
    let thumbnails = [];
    let panelThumbs = [];
    let convoThumbs = [];
    let rapidThumbs = [];

    {Data.panels && Data.panels.map(item => {
      if({id}.id != item.url){
        let panelThumbObj = {};
        panelThumbObj.url = 'video/panels/'+item.url;
        panelThumbObj.thumb = item.yt_thumbnail;
        panelThumbs.push(panelThumbObj);
      }
    })};
    let twoRandPanels = getRandomThumb(panelThumbs, 2);

    {Data.conversations && Data.conversations.map(item => {
      if(item.url != undefined){
        if({id}.id != item.url){
          let convosThumbObj = {};
          convosThumbObj.url = 'video/conversations/'+item.url;
          convosThumbObj.thumb = item.yt_thumbnail;
          convoThumbs.push(convosThumbObj);
        }
      }
    })};
    let twoRandConvos = getRandomThumb(convoThumbs, 2);

    {Data.conversations && Data.conversations.map(item => {
      if(item.rapid_fire_url != undefined){
        if({id}.id != item.rapid_fire_url){
          let rapidThumbObj = {};
          rapidThumbObj.url = 'video/rapid-fire/'+item.rapid_fire_url;
          rapidThumbObj.thumb = item.rapid_fire_img;
          rapidThumbs.push(rapidThumbObj);
        }
      }
    })};
    let twoRandRapids = getRandomThumb(rapidThumbs, 2);

    thumbnails = twoRandPanels.concat(twoRandConvos).concat(twoRandRapids);
    const shuffledThumbs = thumbnails.sort(() => Math.random() - 0.5);
    return shuffledThumbs;
  }

  useEffect(() => {
    const randomX = random(1, 10);
    const randomY = random(1, 10);
    const randomDelay = random(0, 1);
    const randomTime = random(2, 3);
    const randomTime2 = random(1, 2);
    const randomAngle = random(-10, 10);
    const planets = gsap.utils.toArray('.floating');

    planets.forEach(planet => {
      gsap.set(planet, {
        x: randomX(-1),
        y: randomX(1),
        rotation: randomAngle(-1)
      });

      moveX(planet, 1);
      moveY(planet, -1);
      rotate(planet, 1);
    });

    function rotate(target, direction) {
      gsap.to(target, randomTime2(), {
        rotation: randomAngle(direction),
        // delay: randomDelay(),
        ease: Sine.easeInOut,
        onComplete: rotate,
        onCompleteParams: [target, direction * -1]
      });
    }

    function moveX(target, direction) {
      gsap.to(target, randomTime(), {
        x: randomX(direction),
        ease: Sine.easeInOut,
        onComplete: moveX,
        onCompleteParams: [target, direction * -1]
      });
    }

    function moveY(target, direction) {
      gsap.to(target, randomTime(), {
        y: randomY(direction),
        ease: Sine.easeInOut,
        onComplete: moveY,
        onCompleteParams: [target, direction * -1]
      });
    }

    function random(min, max) {
      const delta = max - min;
      return (direction = 1) => (min + delta * Math.random()) * direction;
    }
  });

  const enLink = () =>{ 
    window.open('https://economynext.com/');
  }

  const fnfLink = () =>{ 
    window.open('https://www.freiheit.org/');
  }

  return (
    <>
      <div className='container'>
        <section>
          {Data.conversations && Data.conversations.map(item => {
            if({id}.id == item.url){
              if(item.rapid_fire_url !== undefined){
                isDefined = true;
              }
              return(
                <div className='single-page-section'>
                  <div className='planet en-logo' onClick={enLink}><img src={ENLogo} /></div>
                  <div className='planet fnf-logo' onClick={fnfLink}><img src={FNFLogo} /></div>
                  <div className='en-logo-round planet' onClick={enLink}><img src={ENLogoMobile} /></div>
                  <div className='fnf-logo-round planet' onClick={fnfLink}><img src={FNFLogoMobile} /></div>
                  <img src={SmokeOne} className='background-elements single-smoke-one' />
                  <img src={SmokeTwo} className='background-elements single-smoke-two' />
                  <img src={SmokeOne} className='background-elements single-smoke-three' />
                  <img src={SmokeTwo} className='background-elements single-smoke-four' />
                  <img src={CloudSmoke} className='background-elements single-cloud-smoke' />
                  <img src={Earth} className='background-elements single-earth floating' />
                  <img src={Mars} className='background-elements single-mars floating' />
                  <img src={Saturn} className='background-elements single-saturn floating' />
                  <img src={Uranus} className='background-elements single-uranus floating' />
                  <img src={Neptune} className='background-elements single-neptune floating' />
                  <img src={Moon} className='background-elements single-moon' />
                  <img src={RocketFlames} className='background-elements single-rocket' />
                  <img src={RocketSmoke} className='background-elements single-rocket-smoke' />
                  <div className="star single-page-stars"></div>
                  <div className="star1 single-page-stars"></div>
                  <div className="star2 single-page-stars"></div>
                  <div className="star3 single-page-stars"></div>
                  <div className='header'>
                    <Link to={BackLink} className='logo-container'><img src={LogoImg} /></Link>
                  </div>
                  <div className='row center-vid'>
                    <div className='col-7 col-sm-11'>
                      <div className='video-player-container'><iframe src={item.link} className='video-iframe'></iframe></div>
                    </div>
                    <div className="col-7 col-sm-11">
                      <h2 className='video-title'>{item.title}</h2>
                      <div className='video-description'>{item.description}<div className='end-line'>FNF Sri Lanka partners Echelon for the Rocketship Experience 2022</div></div>
                        <>
                          {isDefined ? (
                            <Link to={"/video/rapid-fire/"+item.rapid_fire_url}>
                              <div className='rapid-fire-link-single'>
                                <img src={RapidFire} />
                                <span className='video-link rapid-fire'>Rapid Fire {playIcon}</span>
                              </div>
                            </Link>
                          ) : null}
                        </>
                      <div className="share-options">
                        <div className='copy-link' onClick={() => navigator.clipboard.writeText(window.location.href)}><svg role="img" aria-label="Copy URL Link" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><title>Copy URL Link</title><path d="M502.6 70.63l-61.25-61.25C435.4 3.371 427.2 0 418.7 0H255.1c-35.35 0-64 28.66-64 64l.0195 256C192 355.4 220.7 384 256 384h192c35.2 0 64-28.8 64-64V93.25C512 84.77 508.6 76.63 502.6 70.63zM464 320c0 8.836-7.164 16-16 16H255.1c-8.838 0-16-7.164-16-16L239.1 64.13c0-8.836 7.164-16 16-16h128L384 96c0 17.67 14.33 32 32 32h47.1V320zM272 448c0 8.836-7.164 16-16 16H63.1c-8.838 0-16-7.164-16-16L47.98 192.1c0-8.836 7.164-16 16-16H160V128H63.99c-35.35 0-64 28.65-64 64l.0098 256C.002 483.3 28.66 512 64 512h192c35.2 0 64-28.8 64-64v-32h-47.1L272 448z"/></svg></div>
                        <a href={"https://m.facebook.com/sharer.php?u=" + window.location.href}><svg role="img" aria-label="Share via Facebook" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><title>Share via Facebook</title><path d="M400 32H48A48 48 0 0 0 0 80v352a48 48 0 0 0 48 48h137.25V327.69h-63V256h63v-54.64c0-62.15 37-96.48 93.67-96.48 27.14 0 55.52 4.84 55.52 4.84v61h-31.27c-30.81 0-40.42 19.12-40.42 38.73V256h68.78l-11 71.69h-57.78V480H400a48 48 0 0 0 48-48V80a48 48 0 0 0-48-48z"/></svg></a>
                        <a href={"https://twitter.com/share?url=" + window.location.href}><svg role="img" aria-label="Share via Twitter" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><title>Share via Twitter</title><path d="M400 32H48C21.5 32 0 53.5 0 80v352c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48V80c0-26.5-21.5-48-48-48zm-48.9 158.8c.2 2.8.2 5.7.2 8.5 0 86.7-66 186.6-186.6 186.6-37.2 0-71.7-10.8-100.7-29.4 5.3.6 10.4.8 15.8.8 30.7 0 58.9-10.4 81.4-28-28.8-.6-53-19.5-61.3-45.5 10.1 1.5 19.2 1.5 29.6-1.2-30-6.1-52.5-32.5-52.5-64.4v-.8c8.7 4.9 18.9 7.9 29.6 8.3a65.447 65.447 0 0 1-29.2-54.6c0-12.2 3.2-23.4 8.9-33.1 32.3 39.8 80.8 65.8 135.2 68.6-9.3-44.5 24-80.6 64-80.6 18.9 0 35.9 7.9 47.9 20.7 14.8-2.8 29-8.3 41.6-15.8-4.9 15.2-15.2 28-28.8 36.1 13.2-1.4 26-5.1 37.8-10.2-8.9 13.1-20.1 24.7-32.9 34z"/></svg></a>
                        <a href={"https://wa.me/?text=" + window.location.href}><svg role="img" aria-label="Share via Whatsapp" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><title>Share via Whatsapp</title><path d="M224 122.8c-72.7 0-131.8 59.1-131.9 131.8 0 24.9 7 49.2 20.2 70.1l3.1 5-13.3 48.6 49.9-13.1 4.8 2.9c20.2 12 43.4 18.4 67.1 18.4h.1c72.6 0 133.3-59.1 133.3-131.8 0-35.2-15.2-68.3-40.1-93.2-25-25-58-38.7-93.2-38.7zm77.5 188.4c-3.3 9.3-19.1 17.7-26.7 18.8-12.6 1.9-22.4.9-47.5-9.9-39.7-17.2-65.7-57.2-67.7-59.8-2-2.6-16.2-21.5-16.2-41s10.2-29.1 13.9-33.1c3.6-4 7.9-5 10.6-5 2.6 0 5.3 0 7.6.1 2.4.1 5.7-.9 8.9 6.8 3.3 7.9 11.2 27.4 12.2 29.4s1.7 4.3.3 6.9c-7.6 15.2-15.7 14.6-11.6 21.6 15.3 26.3 30.6 35.4 53.9 47.1 4 2 6.3 1.7 8.6-1 2.3-2.6 9.9-11.6 12.5-15.5 2.6-4 5.3-3.3 8.9-2 3.6 1.3 23.1 10.9 27.1 12.9s6.6 3 7.6 4.6c.9 1.9.9 9.9-2.4 19.1zM400 32H48C21.5 32 0 53.5 0 80v352c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48V80c0-26.5-21.5-48-48-48zM223.9 413.2c-26.6 0-52.7-6.7-75.8-19.3L64 416l22.5-82.2c-13.9-24-21.2-51.3-21.2-79.3C65.4 167.1 136.5 96 223.9 96c42.4 0 82.2 16.5 112.2 46.5 29.9 30 47.9 69.8 47.9 112.2 0 87.4-72.7 158.5-160.1 158.5z"/></svg></a>
                      </div>
                    </div>
                    <div className="divider"></div>
                    <div className="col-7 col-sm-11">
                      <h3 className='guest-speakers-title'>Guest Speaker</h3>
                      <div className='speaker-info single-speaker-info'>
                        {item.members.first_person && item.members.first_person.map(member => {
                          return(
                            <div className="speaker-profile one-speaker col-sm-12">
                              <img src={member.profile_img} />
                              <div className="speaker-name dummy-mobile">{member.name}</div>
                              <div className="speaker-designation">{member.designation}</div>
                              <div className="speaker-org">{member.company}</div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                    <div className="divider"></div>
                    <div className="col-7 col-sm-11">
                      <h3 className='more-vids-title'>More Videos</h3>
                      <div className="row more-vids-thumbs">
                        {getSixThumbnails() && getSixThumbnails().map(item => {
                          return(
                            <Link to={'../'+item.url} className="col-4">
                              <div className="more-vids-thumb">
                                <img src={'../'+item.thumb} />
                              </div>
                            </Link>
                          );
                        })}
                        <div className='back-to-home-mobile'>
                          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M25.4 278.6L2.7 256l22.6-22.6 144-144L192 66.7 237.2 112l-22.6 22.6L125.2 224 416 224l32 0 0 64-32 0-290.7 0 89.4 89.4L237.2 400 192 445.3l-22.6-22.6-144-144z"/></svg>
                          <Link to={BackLink} >Back</Link>
                        </div>
                      </div>
                      <div className='back-to-home-desktop'>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M25.4 278.6L2.7 256l22.6-22.6 144-144L192 66.7 237.2 112l-22.6 22.6L125.2 224 416 224l32 0 0 64-32 0-290.7 0 89.4 89.4L237.2 400 192 445.3l-22.6-22.6-144-144z"/></svg>
                        <Link to={BackLink} >Back</Link>
                      </div>
                    </div>
                  </div>
                </div>
              );
            }
          })}
        </section>
      </div>
    </>
  );
}