import './App.scss';
import React, {useState} from 'react';
import {gsap} from "gsap";
import {ScrollToPlugin} from 'gsap/ScrollToPlugin';
import Rocket from './assets/images/rocket-without-flames.svg';
import RocketFlames from './assets/images/rocket-with-flames.svg';

export default function ScrollToTop() {
  const [visible, setVisible] = useState(false);
  const [newImg, setNewImg] = useState(0);
  
  const toggleVisible = () => {
    gsap.registerPlugin(ScrollToPlugin);
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > 300){
      setVisible(true);
    } 
    else if (scrolled <= 300){
      setVisible(false);
      setNewImg(Rocket);
    }
  };
  
  const scrollToTop = () =>{
    gsap.to(window, {duration: 2, scrollTo: 0});
  };
  
  window.addEventListener('scroll', toggleVisible);
  
  return (
    <div className='scroll-to-top'>
      <img onClick={() => {scrollToTop(); setNewImg(prevMode => !prevMode);}}
        src={newImg ? Rocket : RocketFlames} style={{display: visible ? 'inline' : 'none'}}
      />
    </div>
  );
}