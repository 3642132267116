import './App.scss';
import React, {useEffect} from 'react';
import {gsap} from "gsap";
import {MotionPathPlugin} from 'gsap/MotionPathPlugin';
import Data from './data.json';
import {Link} from 'react-router-dom';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import ConversationsTitle from './assets/images/conversations-title.png';
import OneToOne from './assets/images/one-to-one-icon.webp';
import RapidFire from './assets/images/rapid-fire-icon.webp';
import BigRock from './assets/images//bg-elements/big-rock.webp';
import SmallRocks from './assets/images//bg-elements/small-rocks.webp';
import Mars from './assets/images/bg-elements/planets/mars.webp';
import Jupiter from './assets/images/bg-elements/planets/jupiter.webp';
import Neptune from './assets/images/bg-elements/planets/neptune.webp';
import Astronaut from './assets/images/bg-elements/astronaut.png';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faPlay} from '@fortawesome/free-solid-svg-icons';

export default function Conversations() {
  gsap.registerPlugin(MotionPathPlugin);
  const playIcon = <FontAwesomeIcon icon={faPlay} />;

  useEffect(() => {
    gsap.to("#flyingRock", {
      duration: 80,
      repeat: -1,
      repeatDelay: 3,
      ease: "power1.inOut",
      motionPath:{
        path: "#bgPath",
        align: "#bgPath",
        autoRotate: true,
        alignOrigin: [0.5, 0.5]
      }
    });
    gsap.to("#flyingAstro", {
      duration: 150,
      repeat: -1,
      repeatDelay: 6,
      ease: "power1.inOut",
      motionPath:{
        path: "#bgAstroPath",
        align: "#bgAstroPath",
        autoRotate: true,
        alignOrigin: [0.5, 0.5]
      }
    });
  }, []);

  return (
    <>
      <div className="container" id='conversations'>
        <section>
          <div className='conversation-section'>
            <img src={BigRock} className='background-elements big-rock-convo-one floating' />
            <img src={BigRock} className='background-elements big-rock-convo-four floating' />
            <img src={BigRock} className='background-elements big-rock-convo-five floating' />
            <img src={BigRock} className='background-elements big-rock-convo-six' id="flyingRock" />
            <img src={Astronaut} className='background-elements astro-convo' id="flyingAstro" />
            <img src={SmallRocks} className='background-elements small-rocks-convo-one floating' />
            <img src={SmallRocks} className='background-elements small-rocks-convo-two floating' />
            <img src={SmallRocks} className='background-elements small-rocks-convo-three floating' />
            <img src={Jupiter} className='background-elements jupiter-convo floating' />
            <img src={Neptune} className='background-elements neptune-convo floating' />
            <img src={Mars} className='background-elements mars-convo floating' />
            <div className='path-container'>
              <svg width="150%" height="100%" viewBox="0 0 2560 1141">
                <path id='bgPath' class="st0" fill="none" d="M2467.65,581.27c0-155.21-245.46-231.27-432.97-329.03C1865.11,163.84,1529.34,46.09,1280,46.09
                  c-220.04,0-470.9,135.51-632.01,206.15C424.64,350.17,75.35,407.87,75.35,603.4c0,164.1,351.3,188.9,574.63,307.27
                  c199.52,105.75,396.46,205.78,630.02,205.78c361.62,0,474.89-98.96,646.94-195.8S2467.65,729.06,2467.65,581.27z"/>
              </svg>
            </div>
            <div className='path-container-two'>
              <svg width="250%" height="100%" viewBox="0 0 2560 1141">
                <path id='bgAstroPath' class="st0" fill="none" d="M2467.65,581.27c0-155.21-245.46-231.27-432.97-329.03C1865.11,163.84,1529.34,46.09,1280,46.09
                  c-220.04,0-470.9,135.51-632.01,206.15C424.64,350.17,75.35,407.87,75.35,603.4c0,164.1,351.3,188.9,574.63,307.27
                  c199.52,105.75,396.46,205.78,630.02,205.78c361.62,0,474.89-98.96,646.94-195.8S2467.65,729.06,2467.65,581.27z"/>
              </svg>
            </div>
            <div className="star"></div>
            <div className="star1"></div>
            <div className="star2"></div>
            <div className="star3"></div>
            <div className='conversation-section-spacing'>
              <div className='conversation-section-title'><img src={ConversationsTitle} /></div>
              {/* desktop */}
              <div class="accordion-container convo-desktop">
                <div className='accordion row-sm'>
                  {/* hiran cooray */}
                  <div className='unit col-sm-4'>
                    <div className='guest-pic'></div>
                    <div className="convo-collapsed-title hiran-title"><img src={Data.conversations[0].title_img} /></div>
                    <div className='guest-alt-pic guest-alt-pic1'><img src={Data.conversations[0].member_astronaut} className='floating' /></div>
                    <div className='expanded-view-title hiran-expanded-title'><img src={Data.conversations[0].title_img} /></div>
                    <div className='guest-video-link-graphics row'>
                      <div className='col-3 conversations-watch-the'>Watch the</div>
                      <div className='col-3 conversations-convos'>
                        <Link to={"/video/conversations/"+Data.conversations[0].url}>
                          <img src={OneToOne} />
                          <span className='video-link one-to-one'>Conversation {playIcon}</span>
                        </Link>
                      </div>
                      <div className='col-3 conversations-watch-the conversations-and'>&</div>
                      <div className='col-3 conversations-rapid-fire'>
                        <Link to={"/video/rapid-fire/"+Data.conversations[0].rapid_fire_url}>
                          <img src={RapidFire} />
                          <span className='video-link rapid-fire'>Rapid Fire {playIcon}</span>
                        </Link>
                      </div>
                    </div>
                  </div>
                  {/* ramesh schaffter */}
                  <div className='unit col-sm-4'>
                    <div className='guest-pic2'></div>
                    <div className="convo-collapsed-title ramesh-schaffter-title"><img src={Data.conversations[1].title_img} /></div>
                    <div className='guest-alt-pic guest-alt-pic2'><img src={Data.conversations[1].member_astronaut} className='floating' /></div>
                    <div className='expanded-view-title ramesh-schaffter-expanded-title'><img src={Data.conversations[1].title_img} /></div>
                    <div className='guest-video-link-graphics row one-vid'>
                      <div className='col-3 conversations-watch-the'>Watch the</div>
                      <div className='col-3 conversations-convos'>
                       <Link to={"/video/conversations/"+Data.conversations[1].url}>
                          <img src={OneToOne} />
                          <span className='video-link one-to-one'>Conversation {playIcon}</span>
                        </Link>
                      </div>
                    </div>
                  </div>
                  {/* ravi jayawardena */}
                  <div className='unit col-sm-4'>
                    <div className='guest-pic3'></div>
                    <div className="convo-collapsed-title "><img src={Data.conversations[2].title_img} /></div>
                    <div className='guest-alt-pic guest-alt-pic1'><img src={Data.conversations[2].member_astronaut} className='floating' /></div>
                    <div className='expanded-view-title ravi-expanded-title'><img src={Data.conversations[2].title_img} /></div>
                    <div className='guest-video-link-graphics row one-vid'>
                      <div className='col-3 conversations-watch-the'>Watch the</div>
                      <div className='col-3 conversations-convos'>
                        <Link to={"/video/conversations/"+Data.conversations[2].url}>
                          <img src={OneToOne} />
                          <span className='video-link one-to-one'>Conversation {playIcon}</span>
                        </Link>
                      </div>
                    </div>
                  </div>
                  {/* Ranil Vitarana */}
                  <div className='unit col-sm-4'>
                    <div className='guest-pic4'></div>
                    <div className="convo-collapsed-title ranil-title"><img src={Data.conversations[3].title_img} /></div>
                    <div className='guest-alt-pic guest-alt-pic2'><img src={Data.conversations[3].member_astronaut} className='floating' /></div>
                    <div className='expanded-view-title ranil-expanded-title'><img src={Data.conversations[3].title_img} /></div>
                    <div className='guest-video-link-graphics row'>
                      <div className='col-3 conversations-watch-the'>Watch the</div>
                      <div className='col-3 conversations-convos'>
                       <Link to={"/video/conversations/"+Data.conversations[3].url}>
                          <img src={OneToOne} />
                          <span className='video-link one-to-one'>Conversation {playIcon}</span>
                        </Link>
                      </div>
                      <div className='col-3 conversations-watch-the conversations-and'>&</div>
                      <div className='col-3 conversations-rapid-fire'>
                       <Link to={"/video/rapid-fire/"+Data.conversations[3].rapid_fire_url}>
                          <img src={RapidFire} />
                          <span className='video-link rapid-fire'>Rapid Fire {playIcon}</span>
                        </Link>
                      </div>
                    </div>
                  </div>
                  {/* ranjith kodithuwakku */}
                  <div className='unit col-sm-4'>
                    <div className='guest-pic5'></div>
                    <div className="convo-collapsed-title ranjith-title"><img src={Data.conversations[4].title_img} /></div>
                    <div className='guest-alt-pic guest-alt-pic1'><img src={Data.conversations[4].member_astronaut} className='floating' /></div>
                    <div className='expanded-view-title ranjith-expanded-title'><img src={Data.conversations[4].title_img} /></div>
                    <div className='guest-video-link-graphics row one-vid'>
                      <div className='col-3 conversations-watch-the'>Watch the</div>
                      <div className='col-3 conversations-convos'>
                        <Link to={"/video/conversations/"+Data.conversations[4].url}>
                          <img src={OneToOne} />
                          <span className='video-link one-to-one'>Conversation {playIcon}</span>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="accordion-container convo-desktop">
                <div className='accordion row-sm'>
                  {/* nandi anthony */}
                  <div className='unit col-sm-4'>
                    <div className='guest-pic6'></div>
                    <div className="convo-collapsed-title"><img src={Data.conversations[5].title_img} /></div>
                    <div className='guest-alt-pic guest-alt-pic1'><img src={Data.conversations[5].member_astronaut} className='floating' /></div>
                    <div className='expanded-view-title nandi-expanded-title'><img src={Data.conversations[5].title_img} /></div>
                    <div className='guest-video-link-graphics row one-vid'>
                      <div className='col-3 conversations-watch-the'>Watch the</div>
                      <div className='col-3 conversations-convos'>
                       <Link to={"/video/conversations/"+Data.conversations[5].url}>
                          <img src={OneToOne} />
                          <span className='video-link one-to-one'>Conversation {playIcon}</span>
                        </Link>
                      </div>
                    </div>
                  </div>
                  {/* vickum nawagamuwage */}
                  <div className='unit col-sm-4'>
                    <div className='guest-pic7'></div>
                    <div className="convo-collapsed-title vickum-title"><img src={Data.conversations[6].title_img} /></div>
                    <div className='guest-alt-pic guest-alt-pic1'><img src={Data.conversations[6].member_astronaut} className='floating' /></div>
                    <div className='expanded-view-title vickum-expanded-title'><img src={Data.conversations[6].title_img} /></div>
                    <div className='guest-video-link-graphics row'>
                      <div className='col-3 conversations-watch-the'>Watch the</div>
                      <div className='col-3 conversations-convos'>
                        <Link to={"/video/conversations/"+Data.conversations[6].url}>
                          <img src={OneToOne} />
                          <span className='video-link one-to-one'>Conversation {playIcon}</span>
                        </Link>
                      </div>
                      <div className='col-3 conversations-watch-the conversations-and'>&</div>
                      <div className='col-3 conversations-rapid-fire'>
                        <Link to={"/video/rapid-fire/"+Data.conversations[6].rapid_fire_url}>
                          <img src={RapidFire} />
                          <span className='video-link rapid-fire'>Rapid Fire {playIcon}</span>
                        </Link>
                      </div>
                    </div>
                  </div>
                  {/* maud meijboom-van wel */}
                  <div className='unit col-sm-4'>
                    <div className='guest-pic8'></div>
                    <div className="convo-collapsed-title maud-title"><img src={Data.conversations[7].title_img} /></div>
                    <div className='guest-alt-pic guest-alt-pic1'><img src={Data.conversations[7].member_astronaut} className='floating' /></div>
                    <div className='expanded-view-title maud-expanded-title'><img src={Data.conversations[7].title_img} /></div>
                    <div className='guest-video-link-graphics row'>
                      <div className='col-3 conversations-watch-the'>Watch the</div>
                      <div className='col-3 conversations-convos'>
                       <Link to={"/video/conversations/"+Data.conversations[7].url}>
                          <img src={OneToOne} />
                          <span className='video-link one-to-one'>Conversation {playIcon}</span>
                        </Link>
                      </div>
                      <div className='col-3 conversations-watch-the conversations-and'>&</div>
                      <div className='col-3 conversations-rapid-fire'>
                       <Link to={"/video/rapid-fire/"+Data.conversations[7].rapid_fire_url}>
                          <img src={RapidFire} />
                          <span className='video-link rapid-fire'>Rapid Fire {playIcon}</span>
                        </Link>
                      </div>
                    </div>
                  </div>
                  {/* ramesh shanmuganathan */}
                  <div className='unit col-sm-4'>
                    <div className='guest-pic9'></div>
                    <div className="convo-collapsed-title ramesh-jkit-title"><img src={Data.conversations[8].title_img} /></div>
                    <div className='guest-alt-pic guest-alt-pic3'><img src={Data.conversations[8].member_astronaut} className='floating' /></div>
                    <div className='expanded-view-title ramesh-jkit-expanded-title'><img src={Data.conversations[8].title_img} /></div>
                    <div className='guest-video-link-graphics row'>
                      <div className='col-3 conversations-watch-the'>Watch the</div>
                      <div className='col-3 conversations-convos'>
                        <Link to={"/video/conversations/"+Data.conversations[8].url}>
                          <img src={OneToOne} />
                          <span className='video-link one-to-one'>Conversation {playIcon}</span>
                        </Link>
                      </div>
                      <div className='col-3 conversations-watch-the conversations-and'>&</div>
                      <div className='col-3 conversations-rapid-fire'>
                        <Link to={"/video/rapid-fire/"+Data.conversations[8].rapid_fire_url}>
                          <img src={RapidFire} />
                          <span className='video-link rapid-fire'>Rapid Fire {playIcon}</span>
                        </Link>
                      </div>
                    </div>
                  </div>
                  {/* roshan rajadurai */}
                  <div className='unit col-sm-4'>
                    <div className='guest-pic10'></div>
                    <div className="convo-collapsed-title roshan-rajadurai-title"><img src={Data.conversations[9].title_img} /></div>
                    <div className='guest-alt-pic guest-alt-pic2'><img src={Data.conversations[9].member_astronaut} className='floating' /></div>
                    <div className='expanded-view-title roshan-rajadurai-expanded-title'><img src={Data.conversations[9].title_img} /></div>
                    <div className='guest-video-link-graphics row'>
                      <div className='col-3 conversations-watch-the'>Watch the</div>
                      <div className='col-3 conversations-convos'>
                        <Link to={"/video/conversations/"+Data.conversations[9].url}>
                          <img src={OneToOne} />
                          <span className='video-link one-to-one'>Conversation {playIcon}</span>
                        </Link>
                      </div>
                      <div className='col-3 conversations-watch-the conversations-and'>&</div>
                      <div className='col-3 conversations-rapid-fire'>
                        <Link to={"/video/rapid-fire/"+Data.conversations[9].rapid_fire_url}>
                          <img src={RapidFire} />
                          <span className='video-link rapid-fire'>Rapid Fire {playIcon}</span>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="accordion-container convo-desktop">
                <div className='accordion row-sm'>
                  {/* channa de silva */}
                  <div className='unit col-sm-4'>
                    <div className='guest-pic11'></div>
                    <div className="convo-collapsed-title channa-lankapay-title"><img src={Data.conversations[10].title_img} /></div>
                    <div className='guest-alt-pic guest-alt-pic2'><img src={Data.conversations[10].member_astronaut} className='floating' /></div>
                    <div className='expanded-view-title channa-lankapay-expanded-title'><img src={Data.conversations[10].title_img} /></div>
                    <div className='guest-video-link-graphics row'>
                      <div className='col-3 conversations-watch-the'>Watch the</div>
                      <div className='col-3 conversations-convos'>
                        <Link to={"/video/conversations/"+Data.conversations[10].url}>
                          <img src={OneToOne} />
                          <span className='video-link one-to-one'>Conversation {playIcon}</span>
                        </Link>
                      </div>
                      <div className='col-3 conversations-watch-the conversations-and'>&</div>
                      <div className='col-3 conversations-rapid-fire'>
                        <Link to={"/video/rapid-fire/"+Data.conversations[10].rapid_fire_url}>
                          <img src={RapidFire} />
                          <span className='video-link rapid-fire'>Rapid Fire {playIcon}</span>
                        </Link>
                      </div>
                    </div>
                  </div>
                  {/* chanakya dissanayake */}
                  <div className='unit col-sm-4'>
                    <div className='guest-pic12'></div>
                    <div className="convo-collapsed-title chanakya-title"><img src={Data.conversations[11].title_img} /></div>
                    <div className='guest-alt-pic guest-alt-pic3'><img src={Data.conversations[11].member_astronaut} className='floating' /></div>
                    <div className='expanded-view-title chanakya-expanded-title'><img src={Data.conversations[11].title_img} /></div>
                    <div className='guest-video-link-graphics row one-vid'>
                      <div className='col-3 conversations-watch-the'>Watch the</div>
                      <div className='col-3 conversations-rapid-fire'>
                        <Link to={"/video/rapid-fire/"+Data.conversations[11].rapid_fire_url}>
                          <img src={RapidFire} />
                          <span className='video-link rapid-fire'>Rapid Fire {playIcon}</span>
                        </Link>
                      </div>
                    </div>
                  </div>
                  {/* malik fernando */}
                  <div className='unit col-sm-4'>
                    <div className='guest-pic13'></div>
                    <div className="convo-collapsed-title malik-title"><img src={Data.conversations[12].title_img} /></div>
                    <div className='guest-alt-pic guest-alt-pic1'><img src={Data.conversations[12].member_astronaut} className='floating' /></div>
                    <div className='expanded-view-title malik-expanded-title'><img src={Data.conversations[12].title_img} /></div>
                    <div className='guest-video-link-graphics row one-vid'>
                      <div className='col-3 conversations-watch-the'>Watch the</div>
                      <div className='col-3 conversations-rapid-fire'>
                        <Link to={"/video/rapid-fire/"+Data.conversations[12].rapid_fire_url}>
                          <img src={RapidFire} />
                          <span className='video-link rapid-fire'>Rapid Fire {playIcon}</span>
                        </Link>
                      </div>
                    </div>
                  </div>
                  {/* rakhil fernando */}
                  <div className='unit col-sm-4'>
                    <div className='guest-pic14'></div>
                    <div className="convo-collapsed-title rakhil-title"><img src={Data.conversations[13].title_img} /></div>
                    <div className='guest-alt-pic guest-alt-pic1'><img src={Data.conversations[13].member_astronaut} className='floating' /></div>
                    <div className='expanded-view-title rakhil-expanded-title'><img src={Data.conversations[13].title_img} /></div>
                    <div className='guest-video-link-graphics row one-vid'>
                      <div className='col-3 conversations-watch-the'>Watch the</div>
                      <div className='col-3 conversations-rapid-fire'>
                        <Link to={"/video/rapid-fire/"+Data.conversations[13].rapid_fire_url}>
                          <img src={RapidFire} />
                          <span className='video-link rapid-fire'>Rapid Fire {playIcon}</span>
                        </Link>
                      </div>
                    </div>
                  </div>
                  {/* kumudu gunasekera */}
                  <div className='unit col-sm-4'>
                    <div className='guest-pic15'></div>
                    <div className="convo-collapsed-title kumudu-title"><img src={Data.conversations[14].title_img} /></div>
                    <div className='guest-alt-pic guest-alt-pic3'><img src={Data.conversations[14].member_astronaut} className='floating' /></div>
                    <div className='expanded-view-title kumudu-expanded-title'><img src={Data.conversations[14].title_img} /></div>
                    <div className='guest-video-link-graphics row one-vid'>
                      <div className='col-3 conversations-watch-the'>Watch the</div>
                      <div className='col-3 conversations-rapid-fire'>
                        <Link to={"/video/rapid-fire/"+Data.conversations[14].rapid_fire_url}>
                          <img src={RapidFire} />
                          <span className='video-link rapid-fire'>Rapid Fire {playIcon}</span>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* mobile */}
              <div class="accordion-container convo-mobile">
                <div className='accordion row-sm'>
                  {/* hiran cooray */}
                  <Popup
                    trigger={
                      <div className='mobile-unit col-sm-4'>
                        <div className='guest-pic'></div>
                        <div className="convo-collapsed-title"><img src={Data.conversations[0].title_img} /></div>
                      </div>
                    }
                    modal
                    nested
                  >
                    {close => (
                      <div className="modal">
                        <button className="close" onClick={close}>&times;</button>
                        <div className="content">
                          {' '}
                          <div className='popup-unit'>
                            <div className='guest-alt-pic float-astro-mobile'><img src={Data.conversations[0].member_astronaut} /></div>
                            <div className='expanded-view-title hiran-expanded-title'><img src={Data.conversations[0].title_img} /></div>
                            <div className='guest-video-link-graphics row'>
                              <div className='conversations-watch-the'>Watch the</div>
                              <div className='conversations-convos'>
                                <Link to={"/video/conversations/"+Data.conversations[0].url}>
                                  <img src={OneToOne} />
                                  <span className='video-link one-to-one'>Conversation {playIcon}</span>
                                </Link>
                              </div>
                              <div className='conversations-watch-the conversations-and'>&</div>
                              <div className='conversations-rapid-fire'>
                                <Link to={"/video/rapid-fire/"+Data.conversations[0].rapid_fire_url}>
                                  <img src={RapidFire} />
                                  <span className='video-link rapid-fire'>Rapid Fire {playIcon}</span>
                                </Link>
                              </div>
                            </div>
                          </div>

                        </div>
                      </div>
                    )}
                  </Popup>
                  {/* ramesh schaffter */}
                  <Popup
                    trigger={
                      <div className='mobile-unit col-sm-4'>
                        <div className='guest-pic2'></div>
                        <div className="convo-collapsed-title"><img src={Data.conversations[1].title_img} /></div>
                      </div>
                    }
                    modal
                    nested
                  >
                    {close => (
                      <div className="modal">
                        <button className="close" onClick={close}>&times;</button>
                        <div className="content">
                          {' '}
                          <div className='popup-unit'>
                            <div className='guest-alt-pic float-astro-mobile ramesh-schaffter-alt'><img src={Data.conversations[1].member_astronaut} /></div>
                            <div className='expanded-view-title ramesh-schaffter-expanded-title'><img src={Data.conversations[1].title_img} /></div>
                            <div className='guest-video-link-graphics row one-vid'>
                              <div className='conversations-watch-the'>Watch the</div>
                              <div className='conversations-convos'>
                                <Link to={"/video/conversations/"+Data.conversations[1].url}>
                                  <img src={OneToOne} />
                                  <span className='video-link one-to-one'>Conversation {playIcon}</span>
                                </Link>
                              </div>
                            </div>
                          </div>

                        </div>
                      </div>
                    )}
                  </Popup>
                  {/* ravi jayawardena */}
                  <Popup
                    trigger={
                      <div className='mobile-unit col-sm-4'>
                        <div className='guest-pic3'></div>
                        <div className="convo-collapsed-title ravi-title"><img src={Data.conversations[2].title_img} /></div>
                      </div>
                    }
                    modal
                    nested
                  >
                    {close => (
                      <div className="modal">
                        <button className="close" onClick={close}>&times;</button>
                        <div className="content">
                          {' '}
                          <div className='popup-unit'>
                            <div className='guest-alt-pic float-astro-mobile'><img src={Data.conversations[2].member_astronaut} /></div>
                            <div className='expanded-view-title ravi-expanded-title'><img src={Data.conversations[2].title_img} /></div>
                            <div className='guest-video-link-graphics row one-vid'>
                              <div className='conversations-watch-the'>Watch the</div>
                              <div className='conversations-convos'>
                                <Link to={"/video/conversations/"+Data.conversations[2].url}>
                                  <img src={OneToOne} />
                                  <span className='video-link one-to-one'>Conversation {playIcon}</span>
                                </Link>
                              </div>
                            </div>
                          </div>

                        </div>
                      </div>
                    )}
                  </Popup>
                  {/* ranil vitarana */}
                  <Popup
                    trigger={
                      <div className='mobile-unit col-sm-4'>
                        <div className='guest-pic4'></div>
                        <div className="convo-collapsed-title"><img src={Data.conversations[3].title_img} /></div>
                      </div>
                    }
                    modal
                    nested
                  >
                    {close => (
                      <div className="modal">
                        <button className="close" onClick={close}>&times;</button>
                        <div className="content">
                          {' '}
                          <div className='popup-unit'>
                            <div className='guest-alt-pic float-astro-mobile ranil-alt'><img src={Data.conversations[3].member_astronaut} /></div>
                            <div className='expanded-view-title ranil-expanded-title'><img src={Data.conversations[3].title_img} /></div>
                            <div className='guest-video-link-graphics row'>
                              <div className='conversations-watch-the'>Watch the</div>
                              <div className='conversations-convos'>
                                <Link to={"/video/conversations/"+Data.conversations[3].url}>
                                  <img src={OneToOne} />
                                  <span className='video-link one-to-one'>Conversation {playIcon}</span>
                                </Link>
                              </div>
                              <div className='conversations-watch-the conversations-and'>&</div>
                              <div className='conversations-rapid-fire'>
                                <Link to={"/video/rapid-fire/"+Data.conversations[3].rapid_fire_url}>
                                  <img src={RapidFire} />
                                  <span className='video-link rapid-fire'>Rapid Fire {playIcon}</span>
                                </Link>
                              </div>
                            </div>
                          </div>

                        </div>
                      </div>
                    )}
                  </Popup>
                  {/* ranjith kodithuwakku */}
                  <Popup
                    trigger={
                      <div className='mobile-unit col-sm-4'>
                        <div className='guest-pic5'></div>
                        <div className="convo-collapsed-title ranjith-title"><img src={Data.conversations[4].title_img} /></div>
                      </div>
                    }
                    modal
                    nested
                  >
                    {close => (
                      <div className="modal">
                        <button className="close" onClick={close}>&times;</button>
                        <div className="content">
                          {' '}
                          <div className='popup-unit'>
                            <div className='guest-alt-pic float-astro-mobile'><img src={Data.conversations[4].member_astronaut} /></div>
                            <div className='expanded-view-title ranjith-expanded-title'><img src={Data.conversations[4].title_img} /></div>
                            <div className='guest-video-link-graphics row one-vid'>
                              <div className='conversations-watch-the'>Watch the</div>
                              <div className='conversations-convos'>
                                <Link to={"/video/conversations/"+Data.conversations[4].url}>
                                  <img src={OneToOne} />
                                  <span className='video-link one-to-one'>Conversation {playIcon}</span>
                                </Link>
                              </div>
                            </div>
                          </div>

                        </div>
                      </div>
                    )}
                  </Popup>
                  {/* nandi anthony */}
                  <Popup
                    trigger={
                      <div className='mobile-unit col-sm-4'>
                        <div className='guest-pic6'></div>
                        <div className="convo-collapsed-title"><img src={Data.conversations[5].title_img} /></div>
                      </div>
                    }
                    modal
                    nested
                  >
                    {close => (
                      <div className="modal">
                        <button className="close" onClick={close}>&times;</button>
                        <div className="content">
                          {' '}
                          <div className='popup-unit'>
                            <div className='guest-alt-pic float-astro-mobile'><img src={Data.conversations[5].member_astronaut} /></div>
                            <div className='expanded-view-title nandi-expanded-title'><img src={Data.conversations[5].title_img} /></div>
                            <div className='guest-video-link-graphics row one-vid'>
                              <div className='conversations-watch-the'>Watch the</div>
                              <div className='conversations-convos'>
                                <Link to={"/video/conversations/"+Data.conversations[5].url}>
                                  <img src={OneToOne} />
                                  <span className='video-link one-to-one'>Conversation {playIcon}</span>
                                </Link>
                              </div>
                            </div>
                          </div>

                        </div>
                      </div>
                    )}
                  </Popup>
                </div>
              </div>
              <div class="accordion-container convo-mobile">
                <div className='accordion row-sm'>
                  {/* vickum nawagamuwage */}
                  <Popup
                    trigger={
                      <div className='mobile-unit col-sm-4'>
                        <div className='guest-pic7'></div>
                        <div className="convo-collapsed-title vickum-title"><img src={Data.conversations[6].title_img} /></div>
                      </div>
                    }
                    modal
                    nested
                  >
                    {close => (
                      <div className="modal">
                        <button className="close" onClick={close}>&times;</button>
                        <div className="content">
                          {' '}
                          <div className='popup-unit'>
                            <div className='guest-alt-pic float-astro-mobile vickum-alt'><img src={Data.conversations[6].member_astronaut} /></div>
                            <div className='expanded-view-title vickum-expanded-title'><img src={Data.conversations[6].title_img} /></div>
                            <div className='guest-video-link-graphics row'>
                              <div className='conversations-watch-the'>Watch the</div>
                              <div className='conversations-convos'>
                                <Link to={"/video/conversations/"+Data.conversations[6].url}>
                                  <img src={OneToOne} />
                                  <span className='video-link one-to-one'>Conversation {playIcon}</span>
                                </Link>
                              </div>
                              <div className='conversations-watch-the conversations-and'>&</div>
                              <div className='conversations-rapid-fire'>
                                <Link to={"/video/rapid-fire/"+Data.conversations[6].rapid_fire_url}>
                                  <img src={RapidFire} />
                                  <span className='video-link rapid-fire'>Rapid Fire {playIcon}</span>
                                </Link>
                              </div>
                            </div>
                          </div>

                        </div>
                      </div>
                    )}
                  </Popup>
                  {/* maud meijboom-van wel */}
                  <Popup
                    trigger={
                      <div className='mobile-unit col-sm-4'>
                        <div className='guest-pic8'></div>
                        <div className="convo-collapsed-title maud-title"><img src={Data.conversations[7].title_img} /></div>
                      </div>
                    }
                    modal
                    nested
                  >
                    {close => (
                      <div className="modal">
                        <button className="close" onClick={close}>&times;</button>
                        <div className="content">
                          {' '}
                          <div className='popup-unit'>
                            <div className='guest-alt-pic float-astro-mobile'><img src={Data.conversations[7].member_astronaut} /></div>
                            <div className='expanded-view-title maud-expanded-title'><img src={Data.conversations[7].title_img} /></div>
                            <div className='guest-video-link-graphics row'>
                              <div className='conversations-watch-the'>Watch the</div>
                              <div className='conversations-convos'>
                                <Link to={"/video/conversations/"+Data.conversations[7].url}>
                                  <img src={OneToOne} />
                                  <span className='video-link one-to-one'>Conversation {playIcon}</span>
                                </Link>
                              </div>
                              <div className='conversations-watch-the conversations-and'>&</div>
                              <div className='conversations-rapid-fire'>
                                <Link to={"/video/rapid-fire/"+Data.conversations[7].rapid_fire_url}>
                                  <img src={RapidFire} />
                                  <span className='video-link rapid-fire'>Rapid Fire {playIcon}</span>
                                </Link>
                              </div>
                            </div>
                          </div>

                        </div>
                      </div>
                    )}
                  </Popup>
                  {/* ramesh shanmuganathan */}
                  <Popup
                    trigger={
                      <div className='mobile-unit col-sm-4'>
                        <div className='guest-pic9'></div>
                        <div className="convo-collapsed-title ramesh-title"><img src={Data.conversations[8].title_img} /></div>
                      </div>
                    }
                    modal
                    nested
                  >
                    {close => (
                      <div className="modal">
                        <button className="close" onClick={close}>&times;</button>
                        <div className="content">
                          {' '}
                          <div className='popup-unit'>
                            <div className='guest-alt-pic float-astro-mobile ramesh-jkit-alt'><img src={Data.conversations[8].member_astronaut} /></div>
                            <div className='expanded-view-title ramesh-jkit-expanded-title'><img src={Data.conversations[8].title_img} /></div>
                            <div className='guest-video-link-graphics row'>
                              <div className='conversations-watch-the'>Watch the</div>
                              <div className='conversations-convos'>
                                <Link to={"/video/conversations/"+Data.conversations[8].url}>
                                  <img src={OneToOne} />
                                  <span className='video-link one-to-one'>Conversation {playIcon}</span>
                                </Link>
                              </div>
                              <div className='conversations-watch-the conversations-and'>&</div>
                              <div className='conversations-rapid-fire'>
                                <Link to={"/video/rapid-fire/"+Data.conversations[8].rapid_fire_url}>
                                  <img src={RapidFire} />
                                  <span className='video-link rapid-fire'>Rapid Fire {playIcon}</span>
                                </Link>
                              </div>
                            </div>
                          </div>

                        </div>
                      </div>
                    )}
                  </Popup>
                  {/* roshan rajadurai */}
                  <Popup
                    trigger={
                      <div className='mobile-unit col-sm-4'>
                        <div className='guest-pic10'></div>
                        <div className="convo-collapsed-title"><img src={Data.conversations[9].title_img} /></div>
                      </div>
                    }
                    modal
                    nested
                  >
                    {close => (
                      <div className="modal">
                        <button className="close" onClick={close}>&times;</button>
                        <div className="content">
                          {' '}
                          <div className='popup-unit'>
                            <div className='guest-alt-pic float-astro-mobile roshan-rajadurai-alt'><img src={Data.conversations[9].member_astronaut} /></div>
                            <div className='expanded-view-title roshan-rajadurai-expanded-title'><img src={Data.conversations[9].title_img} /></div>
                            <div className='guest-video-link-graphics row'>
                              <div className='conversations-watch-the'>Watch the</div>
                              <div className='conversations-convos'>
                                <Link to={"/video/conversations/"+Data.conversations[9].url}>
                                  <img src={OneToOne} />
                                  <span className='video-link one-to-one'>Conversation {playIcon}</span>
                                </Link>
                              </div>
                              <div className='conversations-watch-the conversations-and'>&</div>
                              <div className='conversations-rapid-fire'>
                                <Link to={"/video/rapid-fire/"+Data.conversations[9].rapid_fire_url}>
                                  <img src={RapidFire} />
                                  <span className='video-link rapid-fire'>Rapid Fire {playIcon}</span>
                                </Link>
                              </div>
                            </div>
                          </div>

                        </div>
                      </div>
                    )}
                  </Popup>
                  {/* channa de silva */}
                  <Popup
                    trigger={
                      <div className='mobile-unit col-sm-4'>
                        <div className='guest-pic11'></div>
                        <div className="convo-collapsed-title channa-title"><img src={Data.conversations[10].title_img} /></div>
                      </div>
                    }
                    modal
                    nested
                  >
                    {close => (
                      <div className="modal">
                        <button className="close" onClick={close}>&times;</button>
                        <div className="content">
                          {' '}
                          <div className='popup-unit'>
                            <div className='guest-alt-pic float-astro-mobile channa-lankapay-alt'><img src={Data.conversations[10].member_astronaut} /></div>
                            <div className='expanded-view-title channa-lankapay-expanded-title'><img src={Data.conversations[10].title_img} /></div>
                            <div className='guest-video-link-graphics row'>
                              <div className='conversations-watch-the'>Watch the</div>
                              <div className='conversations-convos'>
                                <Link to={"/video/conversations/"+Data.conversations[10].url}>
                                  <img src={OneToOne} />
                                  <span className='video-link one-to-one'>Conversation {playIcon}</span>
                                </Link>
                              </div>
                              <div className='conversations-watch-the conversations-and'>&</div>
                              <div className='conversations-rapid-fire'>
                                <Link to={"/video/rapid-fire/"+Data.conversations[10].rapid_fire_url}>
                                  <img src={RapidFire} />
                                  <span className='video-link rapid-fire'>Rapid Fire {playIcon}</span>
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </Popup>
                  {/* chanakya dissanayake */}
                  <Popup
                    trigger={
                      <div className='mobile-unit col-sm-4'>
                        <div className='guest-pic12'></div>
                        <div className="convo-collapsed-title"><img src={Data.conversations[11].title_img} /></div>
                      </div>
                    }
                    modal
                    nested
                  >
                    {close => (
                      <div className="modal">
                        <button className="close" onClick={close}>&times;</button>
                        <div className="content">
                          {' '}
                          <div className='popup-unit'>
                            <div className='guest-alt-pic float-astro-mobile chanakya-alt'><img src={Data.conversations[11].member_astronaut} /></div>
                            <div className='expanded-view-title chanakya-expanded-title'><img src={Data.conversations[11].title_img} /></div>
                            <div className='guest-video-link-graphics row one-vid'>
                              <div className='conversations-watch-the'>Watch the</div>
                              <div className='conversations-rapid-fire'>
                                <Link to={"/video/rapid-fire/"+Data.conversations[11].rapid_fire_url}>
                                  <img src={RapidFire} />
                                  <span className='video-link rapid-fire'>Rapid Fire {playIcon}</span>
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </Popup>
                </div>
              </div>
              <div class="accordion-container convo-mobile">
                <div className='accordion row-sm'>
                  {/* malik fernando */}
                  <Popup
                    trigger={
                      <div className='mobile-unit col-sm-4'>
                        <div className='guest-pic13'></div>
                        <div className="convo-collapsed-title"><img src={Data.conversations[12].title_img} /></div>
                      </div>
                    }
                    modal
                    nested
                  >
                    {close => (
                      <div className="modal">
                        <button className="close" onClick={close}>&times;</button>
                        <div className="content">
                          {' '}
                          <div className='popup-unit'>
                            <div className='guest-alt-pic malik-alt float-astro-mobile'><img src={Data.conversations[12].member_astronaut} /></div>
                            <div className='expanded-view-title malik-expanded-title'><img src={Data.conversations[12].title_img} /></div>
                            <div className='guest-video-link-graphics row one-vid'>
                              <div className='conversations-watch-the'>Watch the</div>
                              <div className='conversations-rapid-fire'>
                                <Link to={"/video/rapid-fire/"+Data.conversations[12].rapid_fire_url}>
                                  <img src={RapidFire} />
                                  <span className='video-link rapid-fire'>Rapid Fire {playIcon}</span>
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </Popup>
                  {/* rakhil fernando */}
                  <Popup
                    trigger={
                      <div className='mobile-unit col-sm-4'>
                        <div className='guest-pic14'></div>
                        <div className="convo-collapsed-title"><img src={Data.conversations[13].title_img} /></div>
                      </div>
                    }
                    modal
                    nested
                  >
                    {close => (
                      <div className="modal">
                        <button className="close" onClick={close}>&times;</button>
                        <div className="content">
                          {' '}
                          <div className='popup-unit'>
                            <div className='guest-alt-pic float-astro-mobile'><img src={Data.conversations[13].member_astronaut} /></div>
                            <div className='expanded-view-title rakhil-expanded-title'><img src={Data.conversations[13].title_img} /></div>
                            <div className='guest-video-link-graphics row one-vid'>
                              <div className='conversations-watch-the'>Watch the</div>
                              <div className='conversations-rapid-fire'>
                                <Link to={"/video/rapid-fire/"+Data.conversations[13].rapid_fire_url}>
                                  <img src={RapidFire} />
                                  <span className='video-link rapid-fire'>Rapid Fire {playIcon}</span>
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </Popup>
                  {/* kumudu gunasekera */}
                  <Popup
                    trigger={
                      <div className='mobile-unit col-sm-4'>
                        <div className='guest-pic15'></div>
                        <div className="convo-collapsed-title"><img src={Data.conversations[14].title_img} /></div>
                      </div>
                    }
                    modal
                    nested
                  >
                    {close => (
                      <div className="modal">
                        <button className="close" onClick={close}>&times;</button>
                        <div className="content">
                          {' '}
                          <div className='popup-unit'>
                            <div className='guest-alt-pic float-astro-mobile kumudu-alt'><img src={Data.conversations[14].member_astronaut} /></div>
                            <div className='expanded-view-title rakhil-expanded-title'><img src={Data.conversations[14].title_img} /></div>
                            <div className='guest-video-link-graphics row one-vid'>
                              <div className='conversations-watch-the'>Watch the</div>
                              <div className='conversations-rapid-fire'>
                                <Link to={"/video/rapid-fire/"+Data.conversations[14].rapid_fire_url}>
                                  <img src={RapidFire} />
                                  <span className='video-link rapid-fire'>Rapid Fire {playIcon}</span>
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </Popup>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}